@import '../../../styles/style-constants.less';

#favs {
  .favs__title {
    font-family: Frunchy;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 58px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
    padding-left: 40px;
  }

  .favs__buttons {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  .favs__button {
    border: none;
    text-decoration: underline;
    background-color: transparent;
    font-family: Visby CF;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.03em;
    cursor: pointer;
  }

  .favs-card {
    display: grid;
    max-width: 100%;
    padding-left: 40px;
    margin-top: 30px;
    margin-bottom: 50px;
    position: relative;
  }

  .favs-card__top {
    background-color: #fff;
  }

  .favs-card__image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    cursor: pointer;
  }

  .favs-card__icon {
    position: absolute;
    width: 20px;
  }

  .cart-icon {
    top: 10px;
    right: 45px;
    cursor: pointer;
  }

  .close-icon {
    top: 12px;
    right: 10px;
    cursor: pointer;
  }

  .favs__try {
    font-family: Visby CF;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.03em;
    text-align: center;
    text-decoration: underline;
    margin-top: 10px;
    padding-bottom: 10px;
  }

  .favs-card__bottom {
    display: flex;
    align-items: center;
    padding-top: 15px;
    justify-content: space-between;
  }

  .favs-card__article-name {
    font-family: Visby CF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-align: left;
  }

  .favs-card__price {
    font-family: Visby CF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-align: right;
    height: 100%;
  }

  @media (max-width: @screen-lg) {
    .favs__title {
      font-size: 38px;
      line-height: 43px;
    }

    .favs__button {
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0em;
    }

    .favs-card__icon {
      width: 15px;
    }

    .favs-card__article-name {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.03em;
      width: 65%;
    }

    .favs-card__price {
      font-size: 14px;
      line-height: 17px;
      width: 35%;
    }

    .favs__try {
      font-size: 12px;
      line-height: 15px;
    }
  }

  @media (max-width: @screen-md) {
    .favs__title {
      font-size: 28px;
      line-height: 33px;
    }

    .favs__button {
      font-size: 12px;
      line-height: 11px;
      letter-spacing: 0em;
    }

    .favs-card__icon {
      width: 12px;
    }

    .favs-card__image {
      height: 200px;
    }

    .favs-card__article-name {
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0em;
    }

    .favs-card__price {
      font-size: 13px;
      line-height: 17px;
      letter-spacing: -0.08em;
      width: 45%;
    }

    .favs__try {
      font-size: 10px;
      line-height: 12px;
    }

    .cart-icon {
      top: 10px;
      right: 35px;
    }

    .close-icon {
      top: 10px;
      right: 10px;
    }
  }

  @media (max-width: @screen-sm) {
    padding-left: 0;

    .navbar-toggle__button {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }

    .navbar-toggle__button img {
      width: 20px;
      padding-bottom: 5px;
    }

    .favs__title {
      font-size: 36px;
      line-height: 24px;
      margin-bottom: 20px;
      padding-left: 0;
    }

    .favs__button {
      font-size: 14px;
      line-height: 16px;
    }

    .favs__buttons {
      display: grid;
      grid-template-columns: max-content;
    }

    .favs__logout {
      text-align: left;
      margin-top: 10px;
    }

    .favs-card {
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 30px;
      margin-bottom: 50px;
    }

    .cart-icon {
      top: 8px;
      left: 25px;
    }

    .close-icon {
      top: 8px;
      right: 25px;
    }

    .favs-card__image {
      height: 300px;
    }

    .favs-card__bottom {
      padding-top: 5px;
      justify-content: space-between;
    }

    .favs-card__price {
      font-size: 13px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 0.03em;
    }

    .favs-card__article-name {
      font-size: 13px;
      font-weight: 400;
      line-height: 12px;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../styles/style-constants.less';
@import '../../styles/color.less';

.app-menu {
  .app-menu-list {
    width: max-content;
    border: none;
  }

  &,
  &.menu-item,
  a.menu-item {
    font-family: Frunchy;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.03em;
    color: #000000;
  }

  .menu-item {
    font-size: 36px;
    line-height: 133.5%;
    padding: 0px !important;
    margin-bottom: 16px !important;

    .ant-menu-submenu-title {
      padding: 0px !important;
    }

    .ant-menu-sub.ant-menu-inline {
      background-color: transparent;
      margin-top: 10px;
      list-style-type: circle;
      list-style-position: outside;
      margin-left: 12px;
    }
  }

  .submenu-item {
    font-size: 24px;
    line-height: 32%;
  }

  a:hover,
  a.active {
    color: #863b49;
  }

  a.active {
    // text-decoration: underline;
    color: #863b49;
  }

  .wrapper {
    padding: 0px; //56px
  }

  .actions-buttons {
    display: flex;
    justify-content: flex-end;
    position: relative;
  }

  .img-wrapper {
    display: none;
  }

  .menu-wrapper {
    width: 100%;
  }

  .condition {
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 22.55px;

    letter-spacing: 0.03em;
    // text-decoration-line: underline;
    color: #333333;
  }

  .socialrow {
    display: flex;
    justify-content: flex-start;
  }

  .left-action {
    display: inline-flex;
    position: absolute;
    left: 0px;
    margin-bottom: 18px;
  }

  @media (min-width: @screen-md-min) {
    .wrapper {
      position: relative;
      padding: 40px; //56px
    }

    .actions-buttons {
      position: absolute;
      top: 40px;
      /* width: 97%; */
      right: 28px;
      z-index: 99;
    }

    .menu-item {
      font-size: 48px;
      line-height: 64px;
      margin-bottom: 20px !important;

      // margin-bottom: 0px;
      // height: 50px;
    }

    .submenu-item {
      font-size: 38px;
      line-height: 50.73%;
      // margin-bottom: 28px;
    }

    .img-wrapper {
      width: 40%;
      display: block;

      img {
        width: 100%;
        height: 85vh;
        object-fit: cover;
      }
    }

    .menu-wrapper {
      width: 60%;
    }

    .condition {
      font-size: 16px;
      line-height: 205%;
    }

    .socialrow {
      justify-content: flex-end;
    }

    .left-action {
      display: none;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../../styles/style-constants.less';

#categories-mosaic {
  margin-top: 30px;
  @media (max-width: 765px) {
    scroll-margin-top: 10em;
  }

  .mosaic-title {
    font-family: Frunchy;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    color: #333333;
  }

  .mosaic-wrapper {
    width: 95%;
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 20px;
  }

  .mosaic-card {
    width: 100%;
    position: relative;
    font-style: normal;
    letter-spacing: 0.03em;
    text-align: center;
    cursor: pointer;
    transition: 0.4s ease-in-out;
  }
  .mosaic-card:hover {
    transform: scale(1.05);
    opacity: 0.8;
  }
  .mosaic-card:nth-child(5n + 2) {
    width: 47%;
  }
  .mosaic-card:nth-child(5n + 3) {
    width: 47%;
  }
  .mosaic-card:after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(111, 76, 81, 0) 0%,
      rgba(111, 76, 81, 0.664) 100%
    );
    pointer-events: none;
  }

  .mosaic-card img {
    width: 100%;
    object-fit: cover;
    height: 180px;
  }

  .mosaic-card a {
    color: white;
  }

  .mosaic-card-title {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: Frunchy;
    font-size: 20px;
    font-weight: 400;
    line-height: 15px;
    z-index: 10;
    width: 100%;
  }

  .more {
    position: absolute;
    bottom: 7%;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: Visby CF;
    font-size: 7px;
    font-weight: 400;
    line-height: 5px;
    z-index: 10;
  }

  .btn-section {
    width: 100%;
    display: flex;
    margin-top: 30px;
    justify-content: space-evenly;
    align-items: center;
  }
  .btn-section:before {
    content: url('./../../../assets/icons/straight_line.svg');
  }
  .btn-section:after {
    content: url('./../../../assets/icons/straight_line.svg');
  }

  .base-ads-btn {
    border-radius: 12px;
    border: 2px solid;
  }

  @media (min-width: @screen-xs-min) {
    .mosaic-card img {
      height: 230px;
    }

    .mosaic-card-title {
      font-size: 25px;
      line-height: 15px;
    }
    .more {
      font-size: 11px;
      line-height: 5px;
    }
  }

  @media (min-width: @screen-sm-min) {
    .mosaic-title {
      font-size: 47px;
      line-height: 45px;
    }
    .mosaic-wrapper {
      width: 97%;
      row-gap: 20px;
    }
    .mosaic-card img {
      width: 100%;
      object-fit: cover;
      height: 280px;
    }
    .mosaic-card-title {
      bottom: 12%;
      font-size: 27px;
      line-height: 27px;
    }
  }

  @media (min-width: @screen-md-min) {
    margin-top: 50px;

    .mosaic-title {
      font-size: 65px;
      line-height: 62px;
    }

    .mosaic-wrapper {
      row-gap: 30px;
      margin-bottom: 60px;
    }

    .mosaic-card:nth-child(5n + 1) {
      width: 64%;
    }

    .mosaic-card:nth-child(5n + 2) {
      width: 33%;
    }
    .mosaic-card:nth-child(5n + 3) {
      width: 33%;
    }
    .mosaic-card:nth-child(5n + 4) {
      width: 64%;
    }

    .mosaic-card img {
      height: 280px;
    }

    .btn-section:before {
      transform: scale(2.5);
    }
    .btn-section:after {
      transform: scale(2.5);
    }
  }

  @media (min-width: @screen-lg-min) {
    .mosaic-title {
      font-size: 100px;
      line-height: 90px;
    }

    .mosaic-card img {
      height: 320px;
    }

    .mosaic-card-title {
      font-size: 31px;
      line-height: 30px;
    }
    .more {
      font-size: 13px;
      line-height: 5px;
    }

    .btn-section:before {
      transform: scale(3);
    }
    .btn-section:after {
      transform: scale(3);
    }
  }

  @media (min-width: @screen-xl-min) {
    .mosaic-card img {
      height: 400px;
    }

    .mosaic-card-title {
      font-size: 40px;
      line-height: 40px;
    }

    .more {
      font-size: 14px;
    }

    .btn-section:before {
      transform: scale(4);
    }
    .btn-section:after {
      transform: scale(4);
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
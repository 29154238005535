@import '../../styles/style-constants.less';

#checkout {
  font-family: Visby CF;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.03em;
  color: #333333;

  .terms-field .ant-form-item-explain-error {
    margin-left: 20px;
  }

  .validation {
    margin: 50px 0;
    font-size: 20px;
    font-style: normal;
    line-height: 23px;
    letter-spacing: 0.03em;
  }

  .checkout-breadcrumb {
    margin-top: 30px;
  }

  .section__title {
    font-family: Frunchy;
    font-weight: 400;
    font-size: 48px;
    line-height: 57px;
  }

  .section__subtitle {
    color: #a9a9a9;
    font-size: 16px;
    margin-bottom: 40px;
  }

  .checkout-section {
    margin-top: 0px;
  }

  .ant-col.validation__button.ant-col-lg-5 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .validation__button button {
    cursor: pointer;
    background-color: transparent;
  }

  .validation__command {
    border: 1px solid #333333;
    padding: 20px 40px;
    border-radius: 50%;
  }

  .validation__back {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    background-color: transparent;
    border: none;
  }

  .PhoneInputCountry {
    display: none;
  }

  .validation--display-none {
    display: none;
  }

  .conf--display-none--big {
    display: none;
  }

  .conf--display-none {
    display: none;
  }

  .ant-form-large .ant-form-item-label > label {
    height: min-content;
    margin-bottom: 0px;
  }

  textarea.ant-input {
    width: 85%;
  }

  @media (max-width: 768px) {
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.03em;
    color: #333333;

    .resu--display-none {
      display: none;
    }

    .liv--display-none {
      display: none;
    }

    .info--display-none {
      display: none;
    }

    .conf--display-none {
      display: none;
    }

    .col14 {
      flex: none;
      max-width: 100%;
    }

    .col10 {
      flex: none;
      max-width: 100%;
    }

    .validation__back--small {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0.03em;
      border: none;
      background-color: transparent;
      margin-left: 40px;
    }

    .ant-col.validation__button.ant-col-xs-12 {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .validation__forth {
      border: 1px solid #333333;
      padding: 10px 40px;
      border-radius: 50%;
      font-size: 11px;
    }

    .section__title {
      font-size: 36px;
      line-height: 40px;
    }

    .section__subtitle {
      font-size: 11px;
      line-height: 13px;
      margin-bottom: 20px;
    }
  }

  textarea.ant-input {
    width: 95%;
  }

  @media (max-width: @screen-sm) {
    margin-top: 0;

    .checkout-breadcrumb {
      margin-top: 70px;
    }

    .validation__command {
      border: 1px solid #333333;
      padding: 15px 40px;
      border-radius: 50%;
      font-size: 11px;
      line-height: 11px;
      text-align: center;
    }
  }
  .mailchimp-form{
    display: none;
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../styles/style-constants.less';
@import '../../styles/color.less';

#wishlistPage {
  font-family: Visby CF;
  .producCard {
    display: grid;
    justify-content: center;
  }

  .empty-text {
    font-size: 24px;
    text-align: center;
    width: 100%;
  }

  .products {
    .products-col {
      display: flex;
      flex-flow: row wrap;
    }
    max-width: 1000px;
    align-self: center;
    margin: auto;
    padding: 50px 30px;
  }
  .wishlist-band {
    margin: 2% 0 2% 0;
    width: 100%;
    .band {
      background-color: white;
      box-shadow: 0px 0px 20px #f7f2e9;
      padding: 1% 2% 1% 2%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-seemore {
        text-decoration: underline;
      }
    }
    .band-info,
    .band-seemore {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .wishlistPage-section1 {
    margin: 2%;
    .section1-subtitle {
      display: flex;
      align-items: center;
      justify-content: center;
      .subtitle {
        font-size: 24px;
        color: #333333;
      }
    }
    .section1-buttons,
    .section1-buttons-share,
    .section1-buttons-delete,
    .section1-title {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media (max-width: @screen-md) {
  .wishlist-band {
    .band {
      font-size: 8px;
    }
  }
}
@media (max-width: @screen-sm) {
  .wishlistPage-section1 {
    .section1-title {
      margin-top: 18% !important;
    }
  }
}
@media (max-width: @screen-md) {
  .wishlistPage-section1 {
    margin-top: 12% !important;
    .header-h1-text-italic {
      font-size: 40px;
    }
    .section1-subtitle :nth-child(1) {
      font-size: 14px !important;
    }
  }
}

@media (max-width: @screen-lg) {
  .products {
    padding: 0;
  }
}
@media (max-width: @screen-xl) {
  .wishlistPage-section1 {
    .section1-buttons {
      &-delete {
        min-width: 40% !important;
      }
    }
  }
}

@media (max-width: @screen-xxl) {
  .wishlist-band {
    .band {
      &-info {
        min-width: 45% !important;
      }
    }
  }
  .wishlistPage-section1 {
    .section1-buttons {
      &-delete {
        min-width: 30%;
      }
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import "../../styles/style-constants.less";

#user-profile {

    margin-top: 50px;
    margin-bottom: 100px;

    #user-profile__nav {
        border-right: solid 1px #000000;
    }

    .form__abel{
        padding: 5px;
    }

    .ant-form-item-explain.ant-form-item-explain-error {
        font-size: 20px;
    }

    .user-profile__title {
        font-family: Visby CF;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0.03em;
        text-align: left;
    }

    .hello {
        font-family: Everty Glorial;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0.03em;
        text-align: left;
    }

    .user-profile__list {
        list-style-type: none;
        position: relative;
        justify-content: center;
        background-color: transparent;
    }

    .user-profile__items {
        margin-bottom: 20px;
        font-family: Visby CF;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.03em;
    }

    .user-profile__items img {
        padding-right: 15px;
        width: 30px;
    }

    .user-profile__switch {
        border: transparent;
        background-color: transparent;
        cursor: pointer;
        padding-bottom: 3px;
    }

    .switch--active {
        border-bottom: 1px solid #000000;
    }

    .nodata {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-top: 80px;

        &__text {
            font-size: 40px;
        }
    }

    @media (max-width : @screen-lg) {

        margin-top: 70px;

        .user-profile__items {
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0em;
            margin-bottom: 30px;
        }

        .user-profile__list {
            padding-left: 0;
            margin-top: 20px;
        }
    }

    @media (max-width : @screen-sm) {

        margin-top: 70px;
        
        #user-profile__nav {
            position: absolute;
            border-right: none;
            transition: 0.4;
            background-color: #F7F2E9;
            width: 100%;
            height: 0;
            z-index: 1;
            top: 170px;
            left: 0;
            overflow-x: hidden;
            transition: 0.5s;
        }

        .nav__border-on {
            border-top: solid 1px #000000;
        }

        .nav__border-off {
            border: none;
        }

        .user-profile__items {
            margin-bottom: 15px;
            font-size: 16px;
            line-height: 20px;
        }

        .user-profile__name {
            margin-bottom: 30px;
        }

        .user-profile__list {
            padding-left: 20px;
            margin-top: 20px;
        }

        .user-profile__items img {
            padding-right: 10px;
            width: 30px;
        }
    }
}
@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../../styles/style-constants.less';
@import '../../../styles/color.less';

.cart {
  &__card {
    height: min-content;
    background-color: #fff;
    min-height: 300px;
    margin: 15px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Visby CF;
    padding: 20px;
    span {
      font-weight: bold;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: auto 0;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 90%;
      row-gap: 5px;
    }
    .card-col {
      display: flex;
      height: 100%;
    }
    .card-image-wrapper {
      width: 33%;
      align-items: center;
      justify-content: center;
    }
    .card-image {
      height: 100%;
      width: 95%;
      object-fit: cover;
      margin-right: auto;
      cursor: pointer;
    }
    .card-info {
      flex: 1;
      padding-left: 10px;
    }

    .price-item--unit {
      font-size: 30px;
    }

    .card-price {
      width: 25%;
      .price-list {
        text-align: right;
        margin-left: auto;
      }

      .price-item--remove {
        line-height: 16px;
        button {
          background-color: transparent;
          border: none;
          font-size: 16px;
          margin-top: 10px;
          padding: 0;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: @screen-xl) {
    &__card {
      .info-item {
        &--main {
          font-size: 24px;
          line-height: 27px;
        }
        &--medium {
          font-size: 18px;
          line-height: 25px;
        }
      }
      .card-info {
        width: 35%;
      }
      .card-price {
        width: 25%;
      }
    }
  }

  @media (max-width: @screen-lg) {
    &__card {
      min-height: 230px;

      .info-item {
        letter-spacing: 0;
        &--main {
          font-size: 28px;
          line-height: 34px;
        }
        &--medium {
          font-size: 18px;
          line-height: 22px;
        }
        &--small {
          font-size: 16px;
          line-height: 18px;
        }
        // &--categories {
        //   height: 40px;
        // }
      }
      .price-item--remove button {
        font-size: 14px !important;
      }
    }
  }

  @media (max-width: 850px) {
    &__card {
      min-height: 300px;
    }
  }

  @media (max-width: @screen-sm) {
    &__card {
      min-height: 200px;
      margin: 20px 15px;
      padding: 15px;

      .info-item {
        letter-spacing: 0;
        &--main {
          font-size: 18px;
          line-height: 25px;
        }
        &--medium {
          font-size: 16px;
          line-height: 20px;
        }
        &--small {
          font-size: 14px;
          line-height: 12px;
        }
        // &--categories {
        //   height: 30px;
        // }
      }

      .price-item--unit {
        font-size: 23px;
      }

      .price-item--remove button {
        font-size: 12px !important;
      }
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../../styles/style-constants.less';
@import '../../../styles/color.less';

#product-page {
  font-family: 'Visby CF';
  margin: 80px auto;

  .produits-container {
    width: 100%;
    margin-left: 0;
  }

  .stock-callback-btn {
    border: none;
    background-color: transparent;
    padding: 0;
    text-align: left;
    cursor: pointer;
    color: #333333;
    text-decoration: underline;
    font-size: 14px;
  }

  .remove-callback-btn {
    width: 95%;
    text-align: right;
    img {
      width: 15px;
      margin-left: 10px;
    }
  }
  .has-callback-text {
    margin-bottom: 0;
  }

  .img-big {
    width: 75%;
    height: 600px;
    object-fit: contain;
    cursor: pointer;
  }

  .row-front {
    width: 75%;
    padding: 0 30px;
    justify-content: center;
  }

  .front Img {
    cursor: pointer;
    height: 72px;
    width: 100%;
    object-fit: cover;
  }

  .front Img:hover {
    opacity: 0.75;
    transform: scale(1.03);
  }

  .product-section-img-icons {
    display: flex;
    position: relative;
    justify-content: center;
  }

  .img-right-arrow {
    border: 0;
    background-color: transparent;
    top: 50%;
    left: 80%;
    position: absolute;
    cursor: pointer;
  }

  .product-card-sold-out {
    font-size: 24px;
    text-align: left;
  }

  .img-left-arrow {
    border: 0;
    background-color: transparent;
    top: 50%;
    left: 10%;
    position: absolute;
    cursor: pointer;
    Img {
      transform: rotate(180deg);
    }
  }
  .img-heart {
    top: 5%;
    right: 14%;
    border: 0;
    background-color: transparent;
    position: absolute;
    cursor: pointer;
    Img {
      width: 48px;
      height: 45px;
    }
  }
  .product-section-img-views {
    display: flex;
    justify-content: center;
    margin-top: 5%;
  }
  .product-section-title {
    font-family: Frunchy;
    font-size: 48px;
    color: #333333;
    margin-bottom: 1%;
  }
  .product-section-subtitle {
    font-size: 24px;
    text-transform: uppercase;
    color: #aaaaaa;
    margin-top: 0;
  }
  .product-section-amount {
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;
    color: #000f08;
    margin-bottom: 0;
  }
  .product-section-tax-info {
    font-size: 16px;
    color: #aaaaaa;
    a {
      color: #aaaaaa;
      text-decoration: underline;
    }
  }
  .product-section-buttons {
    font-size: 20px;
    margin-top: 5%;
  }

  .product-button-div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-button {
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.03em;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    cursor: pointer;
  }

  .product-section-description {
    background-color: #f7f2e9;
    margin: 5% 5% 0 0;
    padding: 15px;
    span {
      background-color: #f7f2e9 !important;
    }
    button {
      display: block;
      margin: 0 auto;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid black;
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
    }
    &-title {
      font-size: 24px;
    }
    &-item-content {
      font-weight: lighter;
      font-size: 16px;
    }
    &-item-title {
      font-weight: 600;
    }
    .description-content--restricted {
      p:nth-child(n + 4) {
        display: none;
      }
    }
  }
  .product-section-shipping {
    margin-top: 5%;
    font-size: 24px;
    color: #000f08;
  }

  .product-section-colors {
    display: flex;
    list-style-type: none;
    padding-left: 0;
  }
  .product-section-color-item button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 20px;
    cursor: pointer;
    border: none;
  }

  .quantity-select {
    color: #51262e;
    max-width: max-content;
    cursor: pointer;

    .ant-select-selector {
      border: none !important;
      color: #51262e;
    }
    .ant-select-selection-item {
      font-size: 16px;
    }
    .ant-select-selector:focus {
      border: none !important;
    }
  }

  .product-section-color-item button:focus,
  .product-section-color-item button.selected {
    border: 3px solid #51262e !important;
  }
  .product-section-detail-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.03em;
    margin-top: 20px;
    span {
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
    }
  }
  .product-section-detail-list {
    list-style-type: none;
    padding-left: 0;
    display: flex;
  }
  .product-section-detail-item {
    margin-right: 20px;
    background-color: transparent;
    border: 1px solid #4c282e;
    border-radius: 20px;
    font-size: 16px;
    line-height: 23px;
    padding: 7px 30px;
    cursor: pointer;
  }
  .product-section-detail-item:focus,
  .product-section-detail-item.selected {
    background-color: #4c282e;
    color: white;
  }

  // button.selected {
  //   border: 1px solid #4c282e;
  // }

  .see-more {
    margin: 80px auto;
  }

  .card__wrapper {
    display: grid;
    align-items: center;
    transition: 0.3s ease;
    cursor: pointer;
  }

  .card__wrapper:hover {
    opacity: 0.75;
    transform: scale(1.03);
  }

  .card__header {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: white;
  }

  .card__header svg {
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }

  .card__content {
    width: 80%;
    display: flex;
    justify-content: center;
    background-color: white;
    height: 350px;
  }

  .content__image {
    width: 90%;
    padding: 10px 0;
    object-fit: cover;
  }

  .card__footer {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 10px;
    padding: 0 5px;
  }

  .card__detail-name {
    font-family: Visby CF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-align: left;
    margin-bottom: 0px;
  }

  .card__detail-price {
    font-family: Visby CF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-align: left;
    strike {
      font-size: 12px;
    }
  }

  @media (max-width: @screen-xl) {
    .card__header {
      width: 90%;
    }

    .card__content {
      width: 90%;
    }

    .card__footer {
      width: 90%;
    }

    .product-button {
      font-size: 18px;
      line-height: 20px;
    }

    .product-section-color-item button {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    .product-section-detail-title {
      font-size: 22px;
      line-height: 25px;
      span {
        font-size: 18px;
        line-height: 20px;
      }
    }
    .product-section-detail-item {
      font-size: 14px;
      line-height: 23px;
      padding: 7px 20px;
    }
  }

  @media (max-width: @screen-lg) {
    .base-ads-btn {
      font-size: 14px;
      line-height: 18px;
      padding: 15px 35px;
    }

    .card__header {
      width: 100%;
    }

    .card__content {
      width: 100%;
      height: 300px;
    }

    .card__footer {
      width: 100%;
    }

    .product-button {
      font-size: 16px;
      line-height: 20px;
    }

    .product-section-detail-title {
      font-size: 20px;
      line-height: 23px;
      span {
        font-size: 16px;
        line-height: 18px;
      }
    }
    .product-section-detail-item {
      font-size: 12px;
      line-height: 15px;
      padding: 7px 20px;
    }
  }

  @media (max-width: 768px) {
    .img-big {
      width: 60%;
      height: auto;
    }

    .product-section-img-views {
      display: none;
    }

    .img-right-arrow {
      display: none;
    }

    .img-left-arrow {
      display: none;
    }

    .img-heart Img {
      width: 31px;
      height: 29px;
    }

    .base-ads-btn {
      font-size: 13px;
      line-height: 16px;
      padding: 15px 30px;
    }

    .card__content {
      height: 230px;
    }

    .card__detail-name {
      font-size: 14px;
      line-height: 17px;
    }

    .card__detail-price {
      font-size: 14px;
      line-height: 17px;

      strike {
        font-size: 9px;
      }
    }

    .product-section-colors {
      overflow-x: scroll;
    }
    .product-section-detail-list {
      overflow-x: scroll;
    }
  }

  @media (max-width: @screen-sm) {
    .base-ads-btn {
      font-size: 12px;
      line-height: 14px;
      padding: 10px 25px;
    }

    .product-button {
      font-size: 14px;
      line-height: 20px;
    }

    .product-section-title {
      font-size: 48px;
    }
    .product-section-subtitle {
      font-size: 20px;
    }
    .product-section-amount {
      font-size: 20px;
    }
    .product-section-tax-info {
      font-size: 14px;
    }
    .product-section-item-content {
      font-size: 14px;
    }
    .product-section-shipping {
      font-size: 20px;
    }

    .produit-navigation-wrapper {
      display: none;
    }

    .product__card {
      left: 20%;
      margin-bottom: 50px;
    }

    .card__header {
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: white;
    }

    .card__header svg {
      width: 20px;
      height: 20px;
      margin-right: 20px;
    }

    .card__content {
      width: 60%;
      display: flex;
      justify-content: center;
      background-color: white;
      height: 350px;
    }

    .content__image {
      width: 90%;
      padding: 10px 0;
      object-fit: cover;
    }

    .card__footer {
      width: 60%;
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      padding: 0 5px;
    }

    .card__detail-name {
      font-size: 16px;
      line-height: 21px;
    }

    .card__detail-price {
      font-size: 16px;
      line-height: 21px;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../styles/style-constants.less';
@import '../../styles/color.less';

#healthcare {
  .container {
    margin: 0 auto;
  }

  .text-bolder {
    font-weight: 600;
  }

  hr {
    margin: 50px 7% 11px 7%;
    border: 1px solid #00000033;
  }

  .empty {
    height: 92px;
    display: block;
  }

  .healthcare {
    &-img {
      width: 85%;
      margin-left: 90px;
      margin-top: 35px;
    }

    &-section1 {
      margin: 0 auto;
      position: relative;
      &-absolute {
        position: absolute;
        left: 37%;
        color: @brow
      }
      &-img {
        margin-top: 46px;
        display: flex;
        justify-content: center;
        & > img {
          width: 100%;
        }
      }
      &-text {
        background-color: @brow;
        padding: 58px 98px;
        color: white;
        font-family: 'Visby CF';
        &-left {
          margin-right: 20px;
          font-size: 20px;
        }
        &-right {
          margin-left: 20px;
          font-size: 13px;
        }
      }
    }

    &-section2 {
      margin-top: 70px;
      & > div {
        margin-left: 90px;
      }
      &-title {
        font-family: Frunchy;
        font-size: 84px;
        line-height: 90%;
        margin-bottom: 0;
      }
      &-subtitle {
        font-family: Everty Glorial;
        font-size: 84px;
        line-height: 80.5%;
        color: @brow;
        margin-bottom: 22px;
      }
      &-img {
        width: 386px;
        height: 564px;
      }
      &-rigth {
        padding: 35px 82px;
        font-family: Visby CF;
        font-size: 14px;
        &-text {
          margin-top: 24px;
        }
      }
    }

    &-section3 {
      margin-left: 87px;
      margin-right: 30px;
      margin-top: 70px;
      &-text {
        margin-top: 20px;
        font-family: Visby CF;
        margin-right: 80px;
      }
      &-textrigth {
        font-family: Visby CF;
        margin-right: 60px;
      }
    }

    &-section4 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-left: 90px;

      &-col {
        margin-top: 50px;
        &-title {
          width: 240px;
          font-family: Visby CF;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          color: black;
          margin-bottom: 17px;
        }
        &-text {
          font-family: Visby CF;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 130%;
        }
        &-underline {
          font-family: Visby CF;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 130%;
          text-decoration-line: underline;
        }
      }
    }

    &-section5 {
      margin-left: 58px;
      margin-top: 72px;
      margin-bottom: 63px;
      & img {
        width: 133px;
        height: 132px;
        margin-right: 40px;
      }
    }
  }

  // small phone
  @media (max-width: @screen-sm-max) {
    .ant-row {
      display: inline;
    }

    .text-bolder {
      font-weight: 500;
    }

    .ant-col-10,
    .ant-col-14,
    .ant-col-12 {
      max-width: 100%;
    }

    .container {
      padding: 0px !important;
    }

    b {
      font-weight: 100;
    }

    .healthcare-section1 {
      margin: 63px 0 0 0;
      &-absolute {
        top: 32px;
        left: 40%;
      }
      &-img {
        margin-top: 10px;
        & > img {
          height: 355px;
          width: 100%;
          object-fit: cover;
        }
      }
      &-text {
        padding: 50px 20px;
        &-left {
          font-style: normal;
          font-weight: 200;
        }
        &-right {
          margin-left: 0;
          font-weight: 200;
        }
      }
    }

    .title-sm {
      width: 236px !important;
    }

    .healthcare-section2 {
      margin-top: 23px;
      margin-left: 20px;
      margin-right: 20px;
      &-div {
        display: inline-flex !important;
      }
      &-title {
        font-size: 39px;
        width: 136px;
      }

      & > .ant-row > .ant-col > div {
        display: inline-grid;
      }

      &-subtitle {
        margin-top: 33px;
        font-size: 50px;
      }

      &-img {
        display: none;
      }

      &-rigth {
        padding: 44px 33px;
      }
    }

    .empty {
      height: 0px;
    }

    .healthcare-section3 {
      margin: 30px 16px 0px 24px;
      &-title {
        width: 200px;
      }
      &-text {
        margin-right: 0;
        font-weight: 200;
        color: black;
      }
      &-textrigth {
        font-weight: 300;
      }
    }

    .healthcare-img {
      width: 100%;
      margin-left: 0;
    }

    .healthcare-section4 {
      margin-left: 25px;
      display: block;
    }

    .healthcare-section5 {
      margin-left: 10px;

      & > img {
        width: 66px;
        height: 68px;
        margin-bottom: 14px;
      }
    }
  }
}
//tablette and large phone
@media (min-width: @screen-sm-min) {
}

// large screen
@media (max-width: @screen-lg-min) {
  
}

@media (min-width: @screen-md-min) {
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
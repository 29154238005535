@import "../../../styles/style-constants.less";

.notre-mission-content {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(2, auto);
  row-gap: 12px;
}

.section-content-img {
  width: 108%;
  margin-left: -4%;
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}

.section-content-main {
  grid-row: 2 / 3;
  grid-column: 1 / 2;
}

.align-text-at-end {
  align-self: end;
}

.align-text-at-center {
  //   padding-top: 4rem;
  align-self: center;
  justify-self: center;
}

.section-text-content-wrapper {
  margin-top: 16px;
  margin-bottom: 16px;
}

.section-text-content-wrapper > button {
  margin-top: 1rem;
}

@media (min-width: @screen-md-min) {
  .section-content-wrapper {
    display: grid;
    grid-template-columns: 40% 58%;
    grid-template-rows: 100%;
    column-gap: 12px;
  }

  .section-content-img {
    width: 100%;
    margin-left: unset;
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .section-content-main {
    grid-row-start: 1;
    grid-row-end: 2;

    grid-column-start: 1;
    grid-column-end: 2;
  }

  .section-text-content-wrapper {
    width: 80%;
    margin: auto;
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
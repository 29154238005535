@import '../../../styles/style-constants.less';

.base-ads-btn:disabled {
  cursor: not-allowed;
  border: #fdfdfd 1px solid;
  background-color: rgba(0, 0, 0, 0.05);
  & span {
    opacity: 0.4;
  }
}
.base-ads-btn {
  font-family: Visby CF;
  font-size: 20px;
  // font-size: 16px;

  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  // line-height: 18px;

  letter-spacing: 0.03em;
  text-align: center;
  color: #333333;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  // border: 2.75px solid #333333;
  // box-sizing: border-box;
  // border-radius: 22px;

  border: 1px solid #333333;
  box-sizing: border-box;
  border-radius: 8px;

  padding-left: 50px;
  padding-right: 50px;
  padding-top: 22px;
  padding-bottom: 22px;

  background-color: transparent;

  @media (max-width: @screen-xs-max) {
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 113%;
    /* or 12px */

    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;

    padding-left: 25px;
    padding-right: 25px;

    padding-top: 12px;
    padding-bottom: 12px;
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import "../../../../styles/style-constants.less";

.produits-container {
  width: 160%;
  margin-left: -30%;
  margin-right: auto;
  margin-bottom: 10px;
}

@media (min-width: @screen-md-min) {
  .produits-container {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
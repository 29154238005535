@import '../../../../styles/style-constants.less';

.articles-blog-wrapper {
  width: 100%;
  margin: auto !important;

  // margin-left: -3%;
  // margin-right: auto;

  // display: grid;
  // grid-auto-columns: 82%;
  // grid-auto-flow: column;
  // column-gap: 5%;
  // justify-items: center;
  // align-items: center;

  // overflow-x: scroll;
  // scrollbar-width: none;
  // -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: @screen-xs-max) {
  .articles-blog-wrapper {
    width: 100%;

    // margin-left: -3%;
    // margin-right: auto;

    // display: grid;
    // grid-auto-columns: 82%;
    // grid-auto-flow: column;
    // column-gap: 5%;
    // justify-items: center;
    // align-items: center;

    // overflow-x: scroll;
    // scrollbar-width: none;
    // -ms-overflow-style: none;

    // margin-left: 0;
    // margin-right: 0;

    // display: grid;
    // grid-auto-columns: unset;
    // grid-auto-flow: unset;

    // grid-template-rows: 100%;
    // grid-template-columns: 1fr 1fr 1fr;
    // column-gap: 2%;

    // justify-items: center;
    // align-items: center;
  }
}

.slider-wrapperr {
  margin-left: 5px;
  margin-right: 5px;
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
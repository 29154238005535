@import '../../../../styles/style-constants.less';

.mosaic-sections {
  & {
    display: flex !important;
    flex-wrap: wrap;
    flex-direction: column;
    height: calc(120vh - 70px);
    width: 95%;
    align-items: center;
    row-gap: 15px;
    margin: 70px auto;
  }

  & :nth-child(5n + 6) {
    order: 5;
  }

  & :nth-child(5n + 5) {
    order: 6;
  }

  .mosaic-card-wrapper {
    position: relative;
    width: 47%;
    margin: 0 0;
    transition: 0.4s ease-in-out;
  }

  .mosaic-card-wrapper:hover {
    transform: scale(1.03);
    opacity: 0.8;
  }

  .mosaic-card-wrapper:nth-child(5n + 1) {
    height: 50%;
  }
  .mosaic-card-wrapper:nth-child(5n + 2) {
    height: 20%;
  }

  .mosaic-card-wrapper:nth-child(5n + 3) {
    height: 20%;
  }

  .mosaic-card-wrapper:nth-child(5n + 4) {
    height: 35%;
  }
  .mosaic-card-wrapper:nth-child(5n + 5) {
    height: 35%;
  }
  .mosaic-card-wrapper:nth-child(5n + 6) {
    height: 20%;
  }
  .card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .sublime-img-text {
    font-size: 25px;
    line-height: 25px;
    position: absolute;
    top: 3px;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
  }
  .more {
    position: absolute;
    border: none;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: transparent;
    font-weight: 600;
    white-space: nowrap;
    cursor: pointer;
  }

  @media (min-width: @screen-sm-min) {
    & {
      height: 1000px;
    }
    .sublime-img-text {
      font-size: 30px;
      line-height: 30px;
    }
  }

  @media (min-width: @screen-md-min) {
    & {
      height: 800px;
    }
    .mosaic-card-wrapper {
      position: relative;
      width: 31%;
      margin: 0 0;
    }
    & :nth-child(5n + 6) {
      order: 6;
    }
    & :nth-child(5n + 5) {
      order: 5;
    }
    .mosaic-card-wrapper:nth-child(5n + 1) {
      height: 60%;
    }
    .mosaic-card-wrapper:nth-child(5n + 2) {
      height: 30%;
    }
    .mosaic-card-wrapper:nth-child(5n + 3) {
      height: 30%;
    }
    .mosaic-card-wrapper:nth-child(5n + 4) {
      height: 60%;
    }
    .mosaic-card-wrapper:nth-child(5n + 5) {
      height: 60%;
    }
    .mosaic-card-wrapper:nth-child(5n + 6) {
      height: 30%;
    }
    .sublime-img-text {
      font-size: 35px;
      line-height: 35px;
    }
    .more {
      font-size: 20px;
      bottom: 8%;
      font-weight: 500;
    }
  }

  @media (min-width: @screen-lg-min) {
    & {
      height: 1000px;
    }
    .more {
      opacity: 0;
      bottom: 0;
      transition: 0.4s ease-in-out;
      font-weight: 600;
    }
    .mosaic-card-wrapper:hover .more {
      opacity: 1;
      bottom: 8%;
    }
  }

  @media (min-width: @screen-xl-min) {
    & {
      height: 1200px;
      margin-top: 140px;
    }
    .sublime-img-text {
      font-size: 55px;
      line-height: 55px;
    }
    .more {
      font-size: 26px;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import "../../../../styles/style-constants.less";

.notre-mission-section-wrapper {
  position: relative;
  margin-bottom: 1.4rem;
  z-index: 1;
}

.notre-mission-header {
  margin-bottom: -1.4rem;
}

@media (min-width: @screen-md-min) {
  .notre-mission-header {
    margin-bottom: 0px;
  }

  .section-text-content-wrapper {
    width: 100%;
  }
}

@media (min-width: @screen-lg-min) {
  .notre-mission-header {
    margin-bottom: 0;
  }
}

@media (min-width: @screen-xl-min) {
  .notre-mission-section-wrapper {
    position: relative;
    margin-bottom: 100px;
    z-index: 1;
  }

  .notre-mission-header {
    margin-bottom: -70px;
  }

  .section-text-content-wrapper {
    width: 80%;
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
.info-item {
  letter-spacing: 0;

  &--main {
    font-size: 25px;
    font-weight: 500;
    line-height: 27px;
  }
  &--medium {
    font-size: 18px;
    font-weight: 300;
    line-height: 25px;
  }
  &--small {
    font-size: 16px;
    font-weight: 300;
    line-height: 18px;
  }
  &--categories {
    height: 50px;
  }
  &--detail {
    margin-top: 7px;

    .title {
      text-transform: capitalize;
      font-weight: normal;
    }
    //  .value {
    //    font-weight: bold;
    //  }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../styles/style-constants.less';
#hairmatch {
  margin-top: 83px;

  .hairline-match-section {
    position: relative;
    background-color: #4c282e;
    width: 108%;
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 60px;
    padding-bottom: 60px;
    width: 100%;
  }

  .two-column-layout-wrapper {
    padding-top: 40px;
    padding-bottom: 0px;
  }

  .text-chunk-one {
    margin-bottom: 20px;

    font-family: Visby CF;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-align: left;
    color: white;
  }

  .text-chunk-two {
    font-family: Visby CF;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px;
    letter-spacing: 0.03em;
    text-align: left;
    color: white;
  }

  .first-column {
    margin-bottom: 40px;
  }

  .header-section-text {
    font-family: Visby CF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-align: left;
    margin-bottom: 20px;
  }

  .section-content-text {
    font-family: Visby CF;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px;
    letter-spacing: 0.03em;
    text-align: left;
  }

  .row-of-two-columns-layout {
    margin-bottom: 40px;
  }

  .image-wrapper {
    width: 100%;
    max-height: 243px;
    overflow-y: hidden;

    img{
      width: 100%;
    }
  }

  ol {
    padding-inline-start: 15px;
  }

  .video-content-area {
    height: 200px;
    width: 108%;
    margin-right: auto;
  }

  .simple-text-separator {
    margin-bottom: 40px;
  }

  .hairline-match-text {
    display: none;
  }

  .hailine-match-section {
    margin-bottom: 44px;
    position: relative;
  }

  .sublime-logo {
    display: none;
  }

  .ultra-lace-block {
    width: 108%;
    margin-left: -4%;
    margin-right: auto;

    background-color: #4c282e;

    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .ultra-lace-text {
    font-family: Visby CF;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-align: center;
    color: white;

    p{
      margin-bottom: 0;
    }
  }

  .sublime-signature-wrapper {
    position: absolute;
    top: -40px;
    right: 4%;
  }

  @media (min-width: @screen-md-min) {
    .sublime-signature-wrapper {
      position: absolute;
      top: -110px;
      right: -110px;
    }

    .text-big {
      font-size: 16px;
      line-height: 21px;
    }

    .first-column {
      margin-bottom: 0;
      max-width: 280px;
    }

    .second-column {
      max-width: 280px;
    }

    .row-of-two-columns-layout {
      margin-bottom: 50px;
    }

    .two-column-layout-wrapper {
      padding-left: 50px;
      padding-right: 50px;
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .two-columns-layout {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }

    .sublime-logo {
      display: block;
      width: 120px;
      height: 120px;
    }

    .ultra-lace-block {
      position: absolute;
      width: 70%;
      margin-left: 0;
      margin-right: 0;
      top: -25%;
      left: 15%;

      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      background-color: #4c282e;

      padding-top: 40px;
      padding-bottom: 40px;
      padding-left: 40px;
      padding-right: 40px;
    }

    .hairline-match-text {
      display: block;
      font-family: Everty Glorial;
      font-size: 120px;
      font-style: normal;
      font-weight: 400;
      line-height: 150px;
      letter-spacing: 0.03em;
      text-align: center;
      color: #e4cdb5;
      transform: translateY(-100%);
      z-index: 1;
      position: relative;
      opacity: 0.6;
    }

    .hairline-match-section {
      width: 100%;
      margin-left: 0;
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 40px;
      padding-bottom: 40px;

      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }

    .text-chunk-one {
      margin-bottom: 0;

      font-family: Visby CF;
      font-size: 21px;
      font-style: normal;
      font-weight: 300;
      line-height: 26px;
      letter-spacing: 0.03em;
      text-align: left;

      max-width: 280px;
    }

    .text-chunk-two {
      font-family: Visby CF;
      font-size: 13px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px;
      letter-spacing: 0.03em;
      text-align: left;

      max-width: 280px;
    }
  }

  @media (min-width: @screen-xl-min) {
    margin-top: 55px;

    .ultra-lace-block {
      position: absolute;
      width: 70%;
      margin-left: 0;
      margin-right: 0;
      top: -50%;
      left: 15%;

      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      background-color: #4c282e;

      padding-top: 80px;
      padding-bottom: 80px;
      padding-left: 90px;
      padding-right: 90px;
    }

    .text-big {
      font-family: Visby CF;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 31px;
      letter-spacing: 0.01em;
      text-align: left;
      margin-bottom: 20px;
    }

    .ultra-lace-text {
      font-family: Visby CF;
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 31px;
      letter-spacing: 0.03em;
      text-align: left;
      color: white;
      max-width: 427px;
    }

    .sublime-logo {
      display: block;
      width: 143px;
      height: 143px;
    }

    .hailine-match-section {
      margin-bottom: 160px;
      position: relative;
    }

    .hairline-match-text {
      display: block;
      font-family: Everty Glorial;
      font-size: 186px;
      font-style: normal;
      font-weight: 400;
      line-height: 150px;
      letter-spacing: 0.03em;
      text-align: center;
      color: #e4cdb5;
    }

    .simple-text-separator {
      margin-bottom: 50px;
    }

    .video-content-area {
      height: 290px;
      width: 480px;
      margin-left: 0;
      margin-right: 0;
    }

    ol {
      padding-inline-start: 25px;
    }

    .image-wrapper {
      width: 100%;
      margin-left: 0;
      max-height: 383px;
      overflow-y: hidden;
    }

    .row-of-two-columns-layout {
      margin-bottom: 70px;
    }

    .section-content-text {
      font-family: Visby CF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.03em;
      text-align: left;
    }

    .header-section-text {
      font-family: Visby CF;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 31px;
      letter-spacing: 0.01em;
      text-align: left;

      margin-bottom: 20px;
    }

    .two-column-layout-wrapper {
      padding-left: 80px;
      padding-right: 80px;
      padding-top: 80px;
      padding-bottom: 80px;
    }

    .two-columns-layout {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }

    .first-column {
      margin-bottom: 40px;
      max-width: 480px;
    }

    .second-column {
      max-width: 480px;
    }

    .hairline-match-section {
      width: 100%;
      margin-left: 0;
      padding-left: 80px;
      padding-right: 80px;
      padding-top: 80px;
      padding-bottom: 80px;

      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }

    .text-chunk-one {
      margin-bottom: 0;

      font-family: Visby CF;
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 31px;
      letter-spacing: 0.03em;
      text-align: left;

      max-width: 480px;
    }

    .text-chunk-two {
      font-family: Visby CF;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 21px;
      letter-spacing: 0.03em;
      text-align: left;

      max-width: 480px;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
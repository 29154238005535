@import '../../../styles/style-constants.less';

.cat-opener {
  cursor: pointer;
}

.shop-bottom {
  padding-bottom: 40px;
}


#shop {
  h2,
  p,
  button,
  span {
    color: #333333;
    font-family: Visby CF;
  }

  input :focus {
    background-color: yellow;
    border-color: yellowgreen;
  }

  .form-input {
    width: 160px;
    height: 17px;
    font-family: Visby CF !important;
    font-style: italic !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 130% !important;
    color: #787878 !important;
    padding-left: 0px;
    border: none;
    outline: none;
    background-color: transparent;
    border-bottom: 1.7px solid #443f3f !important;
    border-radius: 0px;
    padding: 13.5px 4px;
  }

  .form-input::placeholder {
    color: #787878 !important;
  }
  .shop-wrapper {
    display: flex;
    // flex-direction: row;
    flex-direction: column;
    margin-top: 50px;
  }

  .shop-products-head {
    display: flex;
    align-items: center;

    @height: 45px;
    height: @height;
    margin-top: 20px;

    .cat-header {
      display: flex;
      align-items: center;
      border: 1px solid #f0f0f0;
      padding: 0px 25px;
      background-color: #fff;
      width: 190px;
      margin-right: 20px;

      h2 {
        font-size: 30px !important;
        margin: 0px;
        font-family: Frunchy !important;
        flex: 1 1;
        line-height: @height;
      }
    }
  }

  .empty-list {
    height: 150px;
    display: flex;
    justify-items: center;
    align-items: center;
    width: 100%;
    font-size: 16px;
    p {
      width: 100%;
      text-align: center;
    }
  }

  .shop {
    &-band {
      display: flex;
      width: 100%;
      background-color: white;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      margin-top: 50px;
      &-text {
        font-family: Visby CF;
        font-style: normal;
        font-weight: 300;
        color: #333333;
        // width: 100%;
        width: 80%;
        &-more {
          font-family: Visby CF;
          font-style: normal;
          font-weight: 400;
          text-decoration-line: underline;
          color: #333333;
        }
      }
    }

    &-input {
      display: inline-flex;
      margin-top: 20px;
      margin-right: auto;
      margin-left: auto;
      width: 100%;
      justify-content: space-between;
      & > div {
        display: inline-flex;
        align-items: center;
      }
      &-searchicon > svg {
        width: 20px;
        height: 20px;
      }
      &-filter {
        & > button {
          border: none;
          background-color: transparent;
        }
      }
    }

    &-products {
      margin-right: auto;
      margin-left: auto;
    }

    &-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 45px;

      &-pageindex {
        padding: 10px;
        cursor: pointer;
      }

      &-pageindexA {
        border-bottom: 1px solid black;
        font-weight: 900;
        border-bottom-width: medium;
        padding: 15px;
        cursor: pointer;
      }
    }
  }

  .see-more {
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 113%;
    text-transform: uppercase;

    color: #333333;
    border: 1px solid #333333;
    box-sizing: border-box;

    border-radius: 50%;
    cursor: pointer;
    width: 171px;
    height: 60px;
    background-color: transparent;
  }

  @media (max-width: 765px) {
    .header-text-section {
      margin-top: 48px;
    }
    .shop-products {
      padding: 64px 0px;
    }
  }

  @media (max-width: @screen-xs-max) {
    padding: 0;
    margin-top: 50px;
    .header-h1-text-normal {
      margin-top: 48px;
    }
    .header-h1-text-italic {
      margin-top: -16px;
    }

    .shop-band-wrapper {
      width: 100%;
      background-color: #fff;
    }

    .shop-band-text {
      font-size: 9px !important;
      margin-right: 0;
      margin-left: 16px;
      line-height: 18px;
    }
    .shop-input-filter > button {
      padding-right: 0;
      padding-left: 8px;
    }
    .ant-btn > span + .anticon {
      margin-left: 0;
    }
    .form-input {
      width: 138px;
    }
    .shop-products {
      margin: 0;
      padding: 0;
    }

    .product-card-icons {
      right: 0;
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
    }
    .see-more {
      width: 140px;
      height: 45px;
    }

    .shop {
      &-input {
        margin-top: 20px;
        margin-bottom: 27px;
      }
      &-band {
        margin-top: 18px;
      }
    }
  }
}

//Outside #shop
.shop {
  &-filters {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    background-color: white;
    min-width: 210px;

    &-title {
      padding: 25px 0 0 0;
      font-family: Visby CF;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 130%;
      text-transform: uppercase;
    }
    &-text {
      font-family: Montserrat,sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
    }
    &-btns {
      text-align: center;
    }
    &-btn {
      font-family: Visby CF;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 113%;
      letter-spacing: 0.03em;
      text-decoration-line: underline;
      color: #333333;
      border: none;
      background: none;
      padding: 25px 25px;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
  &-tri {
    grid-template-columns: none !important;

  }

  @media (max-width: @screen-xs-max) {
    &-filters {
      grid-template-columns: 1fr;
      margin-left: 0;

      &-btns {
        // margin-left: -105px;
      }
    }
    &-input {
      margin-bottom: 27px;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
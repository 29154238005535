@import '../../../../styles/style-constants.less';

.nous-joindre-siblime-img {
  display: none;
}

.sublime-img-text {
  color: #000000;

  font-family: Everty Glorial;
  font-size: 100px;
  font-style: normal;
  font-weight: 400;
  line-height: 81px;
  letter-spacing: -0.015em;
  text-align: center;
  position: relative;
  top: 2.5rem;

  margin-block: 0;
}

.join-button-wrapper {
  text-align: center;
}

.sublime-img-on-mobile {
  display: block;
  max-width: 80%;
  margin: auto;
  height: auto;

  grid-row-start: 2;
  grid-row-end: 3;

  grid-column-start: 1;
  grid-column-end: 2;
}

.nous-joindre-contact {
  grid-row-start: 3;
  grid-row-end: 4;

  grid-column-start: 1;
  grid-column-end: 2;

  font-family: Visby CF;

  font-style: normal;

  letter-spacing: 0.03em;
  text-align: left;

  font-weight: 300;
  font-size: 11px;
  line-height: 130%;

  margin-block: 0;

  height: fit-content;

  color: white !important;
}

.nous-joindre-contact p {
  color: white !important;
  max-width: 100%;
}

.nous-joindre-text {
  grid-row-start: 1;
  grid-row-end: 2;

  grid-column-start: 1;
  grid-column-end: 2;

  font-family: Frunchy;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.03em;
  text-align: left;

  margin-block: 0;

  height: fit-content;

  color: white !important;
}

.nous-joindre {
  height: fit-content;
  background-color: #51262e;

  padding-top: 28px;
  padding-bottom: 20px;
  padding-left: 4%;
  padding-right: 4%;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(4, fit-content);
  column-gap: 4%;
  row-gap: 20px;

  margin-top: 40px;
  position: relative;

  width: 108%;
  margin-left: -4%;
  margin-right: auto;
}

.nous-joindre button {
  color: white !important;
  span {
    color: white !important;
  }
}

@media (min-width: @screen-md-min) {
  .nous-joindre-contact {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    max-width: 50%;
  }

  .sublime-img-on-mobile {
    display: none;
  }

  .join-button-wrapper {
    text-align: left;
  }

  .nous-joindre-contact {
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .sublime-img-text {
    font-size: 67px;
    line-height: 70px;
  }

  .nous-joindre {
    min-height: 427px;
    height: fit-content;

    padding-top: 55px;
    padding-bottom: 55px;
    padding-left: 55px;
    padding-right: 55px;

    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: repeat(3, fit-content);
    column-gap: 4%;
    row-gap: 20px;

    margin-top: 208px;
    position: relative;

    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .nous-joindre-text {
    font-family: Frunchy;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: 0.03em;
    text-align: left;
    width: 51%;
  }

  .nous-joindre-siblime-img {
    display: block;
    position: absolute;
    top: -100px;
    left: 50%;
  }

  .sublime-img {
    width: 380px;
    height: 360px;
    object-fit: cover;
  }
}

@media (min-width: @screen-lg-min) {
  .nous-joindre-contact {
    font-size: 16px;
    line-height: 21px;
  }

  .nous-joindre {
    min-height: 427px;
    height: fit-content;
    padding-top: 78px;
    padding-bottom: 78px;
    padding-left: 70px;
    padding-right: 70px;
  }

  .nous-joindre-text {
    font-size: 65px;
    line-height: 70px;
  }

  .nous-joindre-siblime-img {
    top: -120px;
    left: 50%;
  }

  .sublime-img {
    width: 400px;
    height: 390px;
  }

  .sublime-img {
    width: 491px;
    height: 447px;
    z-index: -1000;
  }
}

@media (min-width: @screen-xl-min) {
  .nous-joindre-contact {
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .nous-joindre {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 50px;
    padding-bottom: 100px;
  }

  .nous-joindre-text {
    font-size: 80px;
    line-height: 90px;
    text-align: left;
  }

  .nous-joindre-siblime-img {
    display: block;
    position: absolute;
    top: -160px;
    left: 50%;
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
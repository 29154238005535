@import '../../../../styles/style-constants.less';

.avantages-area {
  margin-top: 30px;
}
.avant-apres-img-wrapper {
  margin-top: 25px;
}

.avant-apres-img {
  display: grid;
  grid-template-columns: 47.5% 47.5%;
  grid-template-rows: 100%;
  column-gap: 5%;
  height: max-content;
}

.navigation-wrapper {
  margin-top: -1rem;
}

.produit-navigation-wrapper {
  margin-bottom: 80px;
}

.produit-advantage-wrapper {
  display: inline-flex;
  margin: 20px auto;
  flex-wrap: wrap;
  font-family: Visby CF;
  font-size: 0.75rem;
  text-align: center;
  column-gap: 20px;
  row-gap: 30px;
}

.produit-advantage {
  width: 45%;
  margin: 0.5rem auto;
  position: relative;
  background-color: #F7F2E9;
  height: 70px;
  align-items: center;
  border-radius: 5px;
}

.advantage-main-text {
  padding: 5px 10px;
}

.advantage-main-text p{
  margin-bottom: 0;
}

.advantage-number{
  position: absolute;
  font-family: frunchy;
  font-size: 1.7rem;
  line-height: 2rem;
  width: 2rem;
  height: 2rem;
  left: -0.9rem;
  top: -1.2rem;
  background-color: #fff;
  border-radius: 50%;
}

@media (min-width: @screen-sm-min) {
  
  .produit-advantage-wrapper {
    font-size: 1rem;
    column-gap: 40px;
  }
  
  .produit-advantage {
    width: 40%;
    height: 100px;
    border-radius: 10px;
  }
  
  .advantage-main-text {
    padding: 10px 15px;
  }
  
  .advantage-number{
    font-size: 2.5rem;
    line-height: 2.3rem;
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (min-width: @screen-md-min) {

  .avant-apres-img-wrapper {
    margin-top: 50px;
  }

  .navigation-wrapper {
    margin-top: -3rem;
    margin-bottom: 3rem;
  }

  .produit-advantage-wrapper {
    font-size: 1.2rem;
    column-gap: 0;
    justify-content: space-between;
  }
  
  .produit-advantage {
    width: 28%;
    height: 120px;
    border-radius: 10px;
  }
  
  .advantage-main-text {
    padding: 10px 15px;
  }
  
  .advantage-number{
    font-size: 2.5rem;
    line-height: 2.3rem;
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (min-width: @screen-lg-min) {

  .produit-advantage-wrapper {
    font-size: 1.4rem;
    justify-content: space-between;
    margin-top: 50px;
    row-gap: 50px;
  }
  
  .produit-advantage {
    width: 28%;
    height: 140px;
    border-radius: 12px;
  }
  
  .advantage-main-text {
    padding: 14px 20px;
  }
  
  .advantage-number{
    font-size: 4rem;
    line-height: 4rem;
    width: 4rem;
    height: 4rem;
    left: -1.7rem;
    top: -2.1rem;
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
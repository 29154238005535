@import '../styles/style-constants.less';
@import '../styles/color.less';

.mobile-header {
  padding: 0px !important;

  .header-container {
    padding: 0px 21px !important;
  }

  .wrapper {
    width: 100%;
    display: flex;
    align-items: baseline;
  }

  .icon-button {
    cursor: pointer;
  }

  .center {
    flex: 1;
  }

  .rightHeader {
    display: inline-flex;
    margin-top: 16px;
    align-items: center;
    position: relative;

    .search-button {
      margin-top: 3px;
      margin-right: 21px;
      position: absolute;
      left: -32px;
      cursor: pointer;
      transition: all 0.2s ease-out;
      height: 20px;
      width: 20px;

      &.searching{
        height: 0px;
        width: 0px;
      }
    }
  }

  .left-header {
    margin-top: 21px;
  }

  .header-actions {
    background: #ffffff;
    font-family: Visby CF;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #333333;

    padding-bottom: 0px;

    &.searching {
      padding-bottom: 18px;
      box-shadow: 0px 1px 2px 0px rgba(114,111,111,0.75);;
      -webkit-box-shadow: 0px 1px 2px 0px rgba(114,111,111,0.75);;
      -moz-box-shadow: 0px 1px 2px 0px rgba(114,111,111,0.75);;
    }

    .wrapper {
      justify-content: center;
      align-items: center;
      display: flex;
      height: 30px;
    }

    .flex {
      flex: 1;
    }

    a.action {
      color: #333333;
      text-align: center;
      letter-spacing: 0.03em;
      text-decoration-line: underline;
      @media (max-width: 765px) {
        scroll-padding-top: 50em;
      }
    }
  }
}

.lg-header {
  padding: 0px !important;

  font-family: Visby CF;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #333333;

  .wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }

  .left-header {
    position: absolute;
    left: 0;
    top: 72px;
  }

  .rightHeader {
    display: inline-flex;
    position: absolute;
    right: 0;
    top: 72px;
  }

  a {
    color: #333333 !important;
  }

  .rdv-btn {
    margin-left: 20px;
    background-color: transparent;
    cursor: pointer;
  }

  .header-container {
    padding: 0px 21px !important;
  }

  .center {
    flex: 1;
  }
}

.affix-header {
  z-index: 99;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ffffff !important;
}

.ant-menu-inline .ant-menu-item::after {
  border: none;
}

@media (max-width: @screen-md-max) {
  .lg-header .rightHeader {
    flex-direction: column;
    top: 52px;

    .phone-number {
      display: flex;
      height: 26px;
      align-items: center;
      margin-right: 0px;
    }
  }
}

@media (min-width: @screen-lg-min) {
  .lg-header .rightHeader {
    flex-direction: row;
    top: 72px;

    .phone-number {
      display: block;
      height: auto;
      margin-right: 15px;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../styles/style-constants.less';

.not-found {
  position: relative;

  //   margin-bottom: 80px;
  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    row-gap: 35px;
    margin-bottom: 110px;
    position: relative;
    min-height: auto;
  }

  .not-img {
    width: 34%;
    margin-top: 64px;
    height: auto;
  }

  .message h1 {
    font-style: normal;
    font-weight: 400 !important;
    font-size: 5em !important;
    line-height: 74px !important;
    color: #4c282e;
    margin: 0px;
  }

  .ribon {
    position: absolute;
    height: auto;
    width: 14%;

    &.left {
      left: 0;
      top: -60px;
    }

    &.right {
      right: 0;
      bottom: -150px;
    }
  }

  @media (max-width: @screen-sm-min) {
    .content {
      min-height: 73vh;
      margin-bottom: 0px;
    }

    .message h1 {
      font-size: 43px !important;
      line-height: 60px !important;
      margin: 0px;
    }

    .ribon {
      position: absolute;
      height: auto;
      width: 27%;

      &.left {
        left: 0;
        top: 60px;
      }

      &.right {
        right: 0;
        bottom: 0px;
      }
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../styles/style-constants.less';
#achat {
  margin-top: 60px;
  margin-bottom: 60px;

  .header {
    //text-align: center;
    font-size: 27px !important;
    line-height: 27px;
  }

  .confirmOrderAlertBox {
    padding-bottom: 25px;

    .ant-alert-with-description .ant-alert-description {
      font-size: 30px;
      line-height: 30px;
    }
  }

  .productImg {
    max-width: 100%;
  }

  .ant-table {
    background-color: transparent;
  }
  .ant-table-thead > tr > th {
    font-size: 22px !important;
  }
  .ant-table-cell {
    background-color: transparent;
    font-size: 25px !important;
    line-height: 27px;
    // text-align: center;
  }
  .ant-table-pagination {
    display: none;
  }
  .return {
    text-align: center;
  }
  .profile {
    font-size: 24px;
    line-height: 113%;
    letter-spacing: 0.03em;
    text-decoration-line: underline;
    color: rgba(76, 40, 46, 0.91);
  }

  .annule {
    font-size: 28px;
    line-height: 113%;
    letter-spacing: 0.03em;
    text-decoration-line: underline;
    color: rgba(76, 40, 46, 0.42);
  }

  .facture {
    font-size: 25px;
    line-height: 27px;
    color: #262626;
  }

  .factureLeft {
    text-align: end;
  }

  .factureT {
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    color: #22262a;
  }

  .total {
    text-align: end;
  }

  .spaceTotal {
    margin-top: 40px;
  }
  .variation-details {
    .info-item--small {
      font-size: 22px !important;
    }
  }
}

@media screen and (max-width: 600px) {
  #achat {
    .ant-table-cell {
      text-align: center !important;
      font-size: 16px !important;
      padding: 14px 2px !important;
    }

    .ant-table-thead > tr > th {
      font-size: 20px !important;
    }
  }
}

.ant-popover-message {
  font-size: 22px;
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../../styles/style-constants.less';

@imagewidth: 100%; //305px;
@imageheight: 308px;

.article-blog-item {
  width: 100%;
  display: flex;
  flex-flow: column;
  padding-left: 6px;
  padding-right: 6px;

  .article-blog-header {
    font-family: Frunchy;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .article-blog-header-wrapper {
    min-height: 36px;
    width: 80%;
    overflow: hidden;
  }

  .ellipse-right-arrow {
    // position: absolute;
    bottom: 0;
    right: 0;
    width: 30px;
    height: auto;
  }

  .article-img {
    margin-top: 0.5rem;
    width: 100%;
    height: 308px;
    object-fit: cover;
  }

  .article-text {
    font-family: Visby CF;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 1rem;
    min-height: 24px;
  }

  @media (min-width: @screen-md-min) {
    width: @imagewidth;
    display: flex;
    flex-flow: column;
    padding: unset;

    .ellipse-right-arrow {
      width: auto;
      height: auto;
    }

    .article-text {
      font-family: Visby CF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      margin-top: 1rem;
    }

    .article-blog-header-wrapper {
      height: 110px;
      margin: 0;
    }

    .article-blog-header {
      position: relative;
      font-family: Frunchy;
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 0.01em;
      text-align: left;
    }

    .article-img {
      margin-top: 0.5rem;
      width: @imagewidth;
      height: @imageheight;
      object-fit: cover;
    }
  }

  .article-img {
    margin-top: 0.5rem;
    width: 305px;
    height: 308px;
    object-fit: cover;
    max-width: 100%;
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import "../../styles/style-constants.less";
#commitment {
  h2,
  p,
  button,
  span {
    color: #333333;
    font-family: Visby CF;
  }
  .advantage-services-garanti {
    justify-content: center;
  }
  .section-content-img {
    width: 108%;
    margin-left: -4%;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }

  .section-content-main {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
  }

  .align-text-at-end {
    align-self: end;
  }

  .align-text-at-center {
    align-self: center;
    justify-self: center;
  }

  .section-text-content-wrapper {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .c-accompagnement-text-content-wrapper {
    width: 100%;
    margin-bottom: 20px;
  }

  .c-accompagnement-with-text {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .subsection-text {
    font-family: Visby CF;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 130%;
    margin-top: 20px;
    color: black;
  }

  .subsection-text-spe {
    max-width: 52%;
  }

  .section-text-content-wrapper > button {
    margin-top: 1rem;
  }

  .video-content-area {
    // margin-top: 17px;
    height: 195px;
    width: 108%;
    margin-right: auto;
  }

  .img-with-text {
    position: relative;
    top: -1.3rem;
  }

  .section-list-items {
    margin-left: 4%;
    margin-right: 4%;
    margin-bottom: 20px;
    list-style-type: "- ";
    padding-inline-start: 0;
  }

  .section-list-items > li {
    font-family: Visby CF;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.03em;
    text-align: left;

    color: #333333;
  }

  .subsection-header {
    font-family: Frunchy;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #333333;
    width: 120%;
  }

  .c-accompagnement-content-img {
    width: 100%;
  }

  .depuis-treize-ans-content-img {
    width: 100%;
  }

  // .advantage-services-garantie {
  //   display: flex;
  //   flex-flow: column nowrap;
  //   margin-top: 40px;
  //   margin-bottom: 40px;
  //   width: 100%;
  // }
  .advantage-services-garanti {
    margin-left: 80px;
    margin-right: 80px;
  }
  .section-item-card {
    position: relative;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
  }

  .yellow-bg-color {
    background-color: #faf5ed;
  }

  .gray-1-bg-color {
    background-color: #ece6e7;
  }

  .gray-2-bg-color {
    background-color: #dadada;
  }

  .header-section {
    width: 100%;
    position: relative;
  }

  .header-wheel-img {
    position: absolute;
    right: 0px;
    top: 5px;
  }

  .header-section-text {
    width: 90%;
    font-family: Visby CF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-align: left;
  }

  .content-section {
    width: 100%;
    margin-top: 10px;
  }

  .content-section > p {
    font-family: Visby CF;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    text-align: left;
    color: black;

    margin-block: 0;
  }

  .coffrets-section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: 90px;
    &-col {
      &-title {
        margin-top: 59px;
        font-family: Frunchy;
        font-style: normal;
        font-weight: normal;
        font-size: 48px;
        line-height: 90%;
      }
      &-text {
        font-family: Visby CF;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .methode-section {
    margin-top: 59px;
    &-container {
      display: inline-flex;
      margin-top: 39px;
    }
    &-content {
      max-width: 52%;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 44%;
      grid-template-columns: unset;
      overflow-x: scroll;
      margin-right: 46px;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      &-title {
        display: inline-flex;
      }
      &-titleLeft {
        font-family: Visby CF;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 130%;
        letter-spacing: 0.03em;
        color: #333333;
        margin-top: 16px;
      }
      &-text {
        font-family: Visby CF;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 130%;
        margin-right: 55px;
      }
    }
  }

  .make-it-clickable {
    margin-right: 60px;
  }

  @media (max-width: 780px) {
    .coffrets-section {
      margin-left: 0;
      &-col {
        padding: 5px;
        &-title {
          font-size: 30px;
        }
        &-text {
          font-size: 14px;
        }
      }
    }
    .make-it-clickable {
      display: none;
    }
    .header-text-section {
      margin-top: 50px;
    }

    .header-h1-text-italic {
      font-size: 4.25rem;
    }

    .subsection-text-spe {
      max-width: 100%;
    }

    .c-accompagnement-content-img {
      width: 100%;
    }

    .advantage-services-garanti {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  @media (max-width: @screen-sm) {
    .coffrets-section {
      margin-left: 0;
      &-col {
        &-title {
          font-size: 48px;
        }
        &-text {
          font-size: 16px;
        }
      }
    }
  }

  @media (max-width: @screen-xs-max) {
    .header-h1-text-normal {
      margin-top: 48px;
    }
    .header-h1-text-italic {
      margin-top: 4px;
    }
    .container {
      margin-top: 50px !important;
    }
    .subsection-text {
      max-width: 100%;
    }
    .coffrets-section {
      display: block;
      margin-left: 0;
    }
    .make-it-clickable {
      display: none;
    }
    .methode-section-content-titleLeft {
      font-size: 12px;
    }
    .methode-section-content-text {
      margin-right: 10px;
      font-size: 7px;
      line-height: 219%;
    }

    .advantage-services-garanti {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  @media (min-width: @screen-md-min) {
    .c-accompagnement-with-text {
      margin-top: 80px;
      margin-bottom: 80px;
    }

    .c-accompagnement-text-content-wrapper {
      width: unset;
      max-width: 500px;
      margin-left: 80px;
      margin-right: auto;
      margin-bottom: 0;
    }

    .video-content-area {
      margin-top: 70px;
      height: 618px;
      width: 100%;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
    }

    .artisan-sublime-chiffre {
      margin-top: 50px;
    }

    .img-with-text {
      position: relative;
      top: -3rem;
    }

    .section-list-items {
      margin-left: unset;
      margin-right: unset;
      margin-bottom: 40px;
      list-style-type: "- ";
      padding-inline-start: 0;
    }

    .section-list-items > li {
      font-family: Visby CF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.03em;
      text-align: left;
      color: #333333;
    }

    .section-content-wrapper {
      display: grid;
      grid-template-columns: 40% 58%;
      grid-template-rows: 100%;
      column-gap: 12px;
    }

    .section-content-img {
      width: 100%;
      margin-left: unset;
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 2;
      grid-column-end: 3;
    }

    .c-accompagnement-content-img {
      max-height: 518px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 1;
      grid-column-end: 2;
    }

    .depuis-treize-ans-content-img {
      max-height: 100%;
      width: 100%;
      margin-left: auto;
      margin-right: 0;
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 2;
      grid-column-end: 3;
    }

    .section-content-main {
      grid-row-start: 1;
      grid-row-end: 2;

      grid-column-start: 1;
      grid-column-end: 2;
    }

    .c-accompagnement-content-main {
      grid-row-start: 1;
      grid-row-end: 2;

      grid-column-start: 2;
      grid-column-end: 3;
    }

    .section-text-content-wrapper {
      width: 80%;
      margin: auto;
    }

    .subsection-header {
      font-family: Frunchy;
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: 43px;
      letter-spacing: 0em;
      text-align: left;
      color: #333333;
    }

    .depuis-treize-ans-section {
      margin-top: 70px;
    }

    .header-section > h3 {
      font-family: Visby CF;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 31px;
      letter-spacing: 0.03em;
      text-align: left;
      color: black;
    }

    .advantage-services-garantie {
      display: flex;
      flex-flow: row nowrap;
      margin-top: 80px;
      margin-bottom: 100px;
      width: fit-content;
      margin-left: 84px;
      margin-right: auto;
    }

    .header-section-text {
      width: 194px;
      font-family: Visby CF;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 31px;
      letter-spacing: 0.03em;
      text-align: left;
    }

    .section-item-card {
      position: relative;
      width: 329px;
      height: 395px;
      padding: 30px;
    }

    .header-section {
      width: 100%;
      position: relative;
    }

    .section-text-content__p {
      margin-top: 40px;
    }

    .header-wheel-img {
      position: absolute;
      right: unset;
      left: 197px;
      top: 5px;
    }

    .content-section {
      width: 260px;
      margin-top: 19px;
    }
  }

  @media (max-width: 1025px) {
    .advantage-services-garanti {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../../styles/style-constants.less';

#product-card3 {
  width: 250px;
  margin: 25px auto;
  font-size: 16px;

  p {
    margin-bottom: 0;
  }

  .product-card-img-wrapper3 {
    background-color: white;
    position: relative;
    cursor: pointer;
  }

  .product-card-img-wrapper3 > img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  .product-card-detail3 {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    align-items: flex-start;
  }

  .product-card-text3 {
    margin-bottom: 0px;
    line-height: 1.4em;
    max-width: 74%;
  }

  .product-card-amount3 {
    font-weight: 600;
    text-align: end;
    strike {
      font-size: 12px;
    }
    float: right;
  }

  .product-card-trybtn3 {
    margin: 0px;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
    font-family: Visby CF;
    font-size: 14px;
    font-weight: 600;
    padding: 6px 0 10px 0;
    & a {
      color: #333333;
    }
  }

  .anticon svg {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    cursor: pointer;
  }

  .product-card-icons3 {
    position: absolute;
    top: 0;
    right: 10px;
  }

  @media (max-width: 1160px) {
  }

  @media (max-width: @screen-md) {
    .product-card-detail3 {
      font-size: 14px;
    }
  }

  @media (max-width: @screen-xs-max) {
    .product-card-amount3 {
      strike {
        font-size: 9px;
      }
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
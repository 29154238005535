@import '../../../styles/style-constants.less';

.product-card {
  margin: 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.product-card-img-wrapper > img {
  margin: auto;
  height: 170px;
  object-fit: cover;
  cursor: pointer;
}

.product-card-img-wrapper {
  margin: 0 auto;
  background-color: white;
}

.produit-advantage {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-top: 1rem;
}

@media (min-width: @screen-md-min) {

  .product-card-img-wrapper > img {
    width: 95%;
    margin: auto;
    height: 300px;
  }

  .product-card-img-wrapper {
    width: 264px;
    height: 332px;
    background-color: white;
  }

  .product-card-text {
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    max-width: 74%;
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
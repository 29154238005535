@import '../../styles/style-constants.less';

#EventDetail {
  font-family: Visby CF;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.03em;
  color: #000000;
  margin: 80px auto;

  p {
    margin-bottom: 0;
  }

  .evenement {
    margin-top: 80px;
  }

  .textEvent {
    margin-bottom: 0;
  }
  .dateEvents {
    display: flex;
    align-items: center;
  }

  .right-column-events {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .ant-row.doubleImage {
    position: absolute;
    bottom: 0;
  }

  .logoEvents {
    width: 70%;
  }

  .imagelog {
    max-width: 90%;
  }

  .event-title {
    font-family: Frunchy;
    font-size: 70px;
    line-height: 66.5px;
    letter-spacing: 0.03em;
    margin-bottom: 20px;
  }

  .imgEvents {
    max-width: 100%;
  }

  .logoEvents {
    display: flex;
    float: left;
    align-items: flex-end;
  }

  .dateEvents {
    font-size: 24px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.03em;
    color: #333333;
    opacity: 0.58;
  }

  .sub-event-row {
    margin: 80px 0;
  }

  .sub-event-col {
    padding: 0 20px;
  }

  .sub-event-text {
    text-align: justify;
    text-justify: auto;
  }

  .video-div {
    margin-bottom: 120px;
  }

  .video-content-area {
    height: 195px;
    width: 108%;
    margin-right: auto;
  }

  .event-cards {
    height: 500px;
  }

  .event-card__title {
    display: flex;
    height: 50px;
    align-items: center;
  }

  .event-card {
    margin: 0 auto;
  }

  .event-card__image-wrapper {
    display: flex;
    justify-content: center;
  }

  .event-card__img {
    width: 300px;
    height: 300px;
    object-fit: cover;
  }

  .imageRester {
    width: 90%;
  }

  .textRester {
    width: 75%;
    text-align: justify;
    font-weight: 300;
    font-size: 18px;
    align-items: center;
    text-justify: inter-word;
    margin: 20px 0;
  }

  @media (max-width: @screen-lg) {
    .event-cards {
      height: 400px;
    }

    .event-card__title {
      font-size: 14px;
      height: 50px;
    }

    .event-card {
      margin: 0 auto;
    }

    .event-card__img {
      width: 220px;
      height: 220px;
      object-fit: cover;
    }
  }

  @media (max-width: 768px) {
    .event-cards {
      height: 300px;
    }

    .event-card__title {
      font-size: 14px;
      height: 50px;
    }

    .event-card {
      margin: 0 auto;
    }

    .event-card__img {
      width: 170px;
      height: 170px;
      object-fit: cover;
    }

    .imageRester {
      width: 100%;
    }

    .ant-row.doubleImage {
      position: relative;
      margin-bottom: 30px;
    }

    .sub-event-col {
      padding: 0;
    }
  }

  @media (max-width: @screen-sm) {
    .event-cards {
      display: flex;
      height: auto;
      margin-bottom: 80px;
    }

    .event-card__title {
      font-size: 14px;
      height: 50px;
    }

    .event-card {
      margin: 20px auto;
    }

    .event-card__img {
      width: 90%;
      height: 300px;
      object-fit: cover;
    }
  }

  @media (max-width: @screen-xs-max) {
    .evenement {
      margin-bottom: 40px;
    }

    .spaceColumEvents {
      margin-top: 50px;
    }

    .spaceColumEvents img {
      width: 100%;
    }

    .imageRester {
      width: 100%;
    }

    .textRester {
      width: 100%;
      margin-bottom: 50px;
    }
  }

  @media (min-width: 750px) and (max-width: 780px) {
    .header-h1-text-normal {
      font-size: 58px;
      line-height: 45.5px;
    }

    .spaceColumEvents {
      margin-top: 150px;
    }

    .imageRester {
      width: 90%;
    }

    .textRester {
      font-size: 12px;
      width: 100%;
    }
  }

  @media (min-width: @screen-md-min) {
    .video-content-area {
      height: 618px;
      width: 100%;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import './color.less';
@import './font.less';
@import '~antd/dist/antd.less';
@import './style-constants.less';

.make-mb(@size) {
  margin-bottom: @size * 4px;
}

html {
  overflow: hidden;
  width: 100%;
  scroll-behavior: smooth;
}
body {
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.container {
  width: 100%;
  align-self: center;
  margin: auto;
  // padding: 0px 2%;
}

h1,
h2,
h3,
h4 {
  color: inherit;
}

@max-container-xs: 100%;
@max-container-sm: 540px;
@max-container-md: 92%; // 732px;;
@max-container-lg: 960px;
// @max-container-xl: 1160px;
@max-container-xl: 1200px;
@max-container-xxl: 1200px;

.make-container(@minWidth, @breakpoint) {
  @media (min-width: @minWidth) {
    .container {
      max-width: @breakpoint;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.make-container(@screen-sm-min, @max-container-sm);
.make-container(@screen-md-min, @max-container-md);
.make-container(@screen-lg-min, @max-container-lg);
.make-container(@screen-xl-min, @max-container-xl);
.make-container(@screen-xxl-min, @max-container-xxl); // Optional

.bg-opacity {
  background: rgba(247, 242, 233, 0.91);
}

.modal-closebtn {
  position: absolute;
  right: 32px;
  top: 45px;
  border: none;
}

.ant-modal-body {
  padding: 40px 40px 35px 40px;
}

.modal-closebtn > span > svg {
  font-size: 28px !important;
}

// header container
.header-container {
  .container;
  padding: 0px 1% !important;
}

.section-title {
  font: normal normal bold 40px/50px Georgia Bold !important;
  color: #3b052b;
}

.section-title-regular {
  font: normal normal normal 40px/50px Georgia Regular !important;
  color: #3b052b;
}

.strapi-description {
  font-size: 18px;
  line-height: 30px;
  font-family: Georgia Regular;
  color: #707070;
  strong {
    font-family: Georgia Bold !important;
  }
}

.section-description ul,
.strapi-description ul {
  padding-left: 18px;
  margin: 0px;
}

.section-description {
  font: normal normal normal 18px/30px Helvetica Neue Regular;
  color: #707070;

  strong {
    font: normal normal bold 18px/30px Helvetica Neue Bold;
  }
}

@media (max-width: @screen-xs-max) {
  .container {
    max-width: 100%;
    padding: 0px 4%;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: @screen-sm-min) {
  .header-container {
    padding: 0px 1% !important;
  }
}

@media (min-width: @screen-lg-min) {
  .header-container {
    padding: 0px 4.4% !important;
  }
}

@media (min-width: 1091px) {
  .ant-modal {
    max-width: 100% !important;
    width: 94%;
    margin: 0 auto 34px;
  }
}

@media (max-width: 1421px) {
  .ant-modal {
    width: 94% !important;
    margin: 0 auto 34px;
  }
}
@media (max-width: @screen-xs-min) {
  .ant-modal {
    max-width: 100% !important;
    width: 100% !important;
    margin: 0;
    top: 0;
  }
  .ant-modal-body {
    padding: 0;
  }
}

@media (max-width: 425px) and (min-width: 320px) {
  .ant-row {
    // display : grid;
  }
}

.product-card-sold-out {
  margin: 0;
  text-align: center;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  padding: 8px 0;
  text-transform: uppercase;
  color: #ff2929 !important;
  font-family: Visby CF;
  font-size: 14px;
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
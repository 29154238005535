@import '../../../styles/style-constants.less';
@import '../../../styles/color.less';

.ant-modal {
  width: 70%;
}

.socialB {
  float: right;
  align-items: baseline;
}

#signin,
#signup {
  display: inline-flex;
  width: 100%;

  .connectIcon {
    width: 100%;
    width: 25px;
  }

  .ant-form-item-explain-error {
    font-size: 20px;
    font-weight: bold;
  }

  .modal-closebtn > span > svg {
    font-size: 28px !important;
  }

  .anticon-eye > svg {
    position: absolute;
    top: 11px;
    right: 2px;
    font-size: 20px;
  }
  .anticon-instagram > svg {
    margin-bottom: -3px !important;
    font-size: 18px !important;
  }

  input :focus {
    background-color: yellow;
    border-color: yellowgreen;
  }

  .form-input {
    font-family: Visby CF !important;
    font-style: italic !important;
    font-weight: normal !important;
    font-size: 20px !important;
    line-height: 130% !important;
    color: #787878 !important;
    padding-left: 0px;
    border: none;

    outline: none;
    background-color: transparent;

    border-bottom: 0.7px solid #443f3f !important;
    border-radius: 0px;
    padding: 6.5px 11px;
  }

  .form-input > input {
    font-family: Visby CF !important;
    font-style: italic !important;
    font-weight: normal !important;
    font-size: 20px !important;
    line-height: 130% !important;
    color: #787878 !important;
  }

  .form-input::placeholder,
  .form-input > input::placeholder {
    color: #787878 !important;
  }

  .content-form {
    width: 100%;
    margin-left: 23px;

    &-savepwd {
      font-family: Visby CF;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 130%;
      color: #000f08;
      margin: 0;
      margin-top: 15px;
    }

    &-button {
      width: 100%;
      display: inline-flex;
      align-items: baseline;
      justify-content: flex-end;

      margin-top: 10px;
      margin-bottom: 10px;
    }

    &-flatbtn {
      display: inline-flex;

      &-btn {
        font-family: Visby CF;
        font-style: normal;
        font-size: 12px;
        text-align: center;
        text-decoration-line: underline;
        text-transform: capitalize;
        color: #333333;
        // margin-right: 10px;
        cursor: pointer;
        padding-left: 0px;
        span {
          text-decoration: underline;
        }
      }
    }
    &-buttonf {
      margin-left: 15px;
      margin-right: 15px;
      // border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 5px;
      // padding: 0px 25px;

      & > img {
        margin: 0 15px 0 6px;
      }
    }
    &-buttong {
      margin-right: 15px;
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 5px 35px;

      & > img {
        margin: 0 15px 0 6px;
      }
    }
    &-buttonrs {
      display: inline-flex;
    }
  }

  .form-title {
    font-family: Frunchy;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 66.5%;
    color: #000;
  }

  .signin-buton {
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 113%;

    color: #333333;
    border: 1px solid #333333;
    box-sizing: border-box;

    border-radius: 50%;
    cursor: pointer;
    width: 150px;
    height: 52px;
    // margin-top: 45px;
    background-color: transparent;
  }

  .signin-error {
    height: 100%;
    display: flex;
    align-items: center;

    &__text {
      font-family: Visby CF;
      font-size: 18px;
      line-height: 130%;
      color: @secondary;
    }
  }

  .illustration-img {
    max-width: 270px;
  }

  @media (max-width: 960px) {
    margin-top: 45px;
    margin-bottom: 30px;

    .illustration-img {
      display: none;
    }

    .content-form {
      margin-top: 0px;
      width: 85%;
    }

    .content-form-buttonf {
      padding: 5px 10px;
    }

    .content-form-flatbtn {
      position: relative;
      width: 100%;
    }

    .form-input {
      font-family: Visby CF;
      font-style: normal;
      font-weight: normal;
      font-size: 14px !important;
      line-height: 130%;
      color: #aaaaaa;
      padding: 5px 5px 5px 0px;
    }
  }

  .mobile-align-center {
    margin: unset;
    text-align: unset;
  }

  @media (max-width: @screen-xs-max) {
    .mobile-align-center {
      margin: auto;
      text-align: center;
    }

    .content-form {
      &-button {
        justify-content: center;
      }
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
#newPassword {
  margin-top: 77px;
  font-size: 22px;
}

#newPassword .content-form {
  width: 68% !important;
  margin: 0 auto !important;
}

.myInput {
  margin: 0 auto;
}

.ant-input {
  font-size: 20px;
}

.content-form-title {
  font-size: 27px;
}

.ant-form-vertical .ant-form-item-label > label {
  font-size: 25px;
  line-height: 20px;
}

.ant-form-item-explain.ant-form-item-explain-error {
  font-size: 20px;
  margin-bottom: 10px;
}

.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label,
.ant-form-vertical .ant-form-item-label > label {
  font-size: 21px;
}

.form-button {
  font-size: 20px;
  font-weight: bold;
}
.ant-form-item-explain.ant-form-item-explain-error {
  font-size: 20px;
  font-weight: 600;
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../../styles/style-constants.less';

#informations {
  margin-top: 0px;

  // .infos-form-field {
  //   // max-width: 43%;
  // }

  .lijuNp {
    padding: 0;
  }

  .ant-input {
    background-color: white;
  }

  .infos-form-input {
    height: 46px;
    font-size: 16px;
    background-color: #fff;
  }

  .ant-select-selector {
    .infos-form-input;
  }

  .PhoneInputInput {
    width: 100%;
    .infos-form-input;
    padding: 8px 20px;
    border: 1px solid #d9d9d9;
  }

  // @media (max-width: @screen-lg) {
  //   .infos-form-field {
  //     // max-width: 70%;
  //     margin-left: 60px;
  //   }
  // }

  @media (max-width: 768px) {
    .infos-form-field {
      // max-width: 50%;
      margin-left: 0;
    }
  }

  @media (max-width: @screen-xs) {
    .infos-form-field {
      max-width: 100%;
    }

    input {
      padding: 7px 20px;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
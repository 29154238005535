@import '../../../styles/style-constants.less';

#categories-banner {
  position: relative;

  .caption {
    position: absolute;
    font-family: Everty Glorial;
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.03em;
    text-align: center;
    color: #fff;
    bottom: 0;
    left: 30%;
    transform: translate(-20%, 0);
  }

  .banner-img-wrapper-m {
    position: relative;
  }

  .banner-img-wrapper-m:after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background: linear-gradient(
      180deg,
      rgba(111, 76, 81, 0) 0%,
      rgba(111, 76, 81, 0.664) 100%
    );
  }

  .header-h1-text-italic {
    margin-bottom: 10px;
  }

  .header-h1-text-normal {
    padding-bottom: 0;
    font-size: 40px;
  }

  @media (min-width: @screen-sm-min) {
    .caption {
      font-size: 45px;
      line-height: 50px;
    }
    .header-h1-text-italic {
      font-size: 60px;
      padding-bottom: 15px;
    }
    .header-h1-text-normal {
      padding: 25px;
      font-size: 60px;
    }
  }

  @media (min-width: @screen-md-min) {
    .header-h1-text-normal {
      padding-top: 20px;
      padding-bottom: 0;
      font-size: 80px;
    }
    .header-h1-text-italic {
      font-size: 70px;
      padding-bottom: 15px;
    }
  }

  @media (min-width: @screen-lg-min) {
    .caption {
      font-size: 70px;
      line-height: 72px;
    }
    .header-h1-text-normal {
      padding-top: 20px;
      line-height: 110px;
      font-size: 100px;
    }
    .header-h1-text-italic {
      font-size: 6.25rem;
      padding-bottom: 15px;
    }
  }
  @media (min-width: @screen-xl-min) {
    .caption {
      font-size: 80px;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
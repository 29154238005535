@import '../../styles/style-constants.less';

#conditions {
  .content-condition {
    padding: 40px 0 60px 0;
  }
  .title-condition {
    font-size: 48px;
  }
  .subtitle-condition {
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 113%;
    /* or 27px */

    letter-spacing: 0.03em;
    text-transform: uppercase;

    /* Gray 1 */

    color: #333333;
  }

  .text-Condition {
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    /* or 21px */

    text-align: justify;
    letter-spacing: 0.03em;

    /* Gray 1 */

    color: #333333;
  }

  .text-Condition__adresse {
    text-justify: none;
  }

  .font-collapse-condition .ant-collapse-header {
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 268.5%;
    /* or 54px */

    letter-spacing: 0.03em;

    /* Gray 1 */

    color: #333333;
  }
  .font-collapse-condition .ant-collapse-content-box p {
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    /* or 21px */

    letter-spacing: 0.03em;

    /* Gray 1 */

    color: #333333;
  }
}

@media (max-width: 768px) {
  #conditions {
    margin-top: 30px;

    .title-condition {
      line-height: 32px;
    }

    .subtitle-condition {
      font-size: 17px;
    }
  }
}

@media (max-width: @screen-xs-max) {
  #conditions {
    .content-condition {
      padding: 80px 0 60px 0;
    }
    .title-condition {
      font-size: 42px;
    }
    .text-Condition {
      font-size: 16px;
    }

    .font-collapse-condition .ant-collapse-header {
      font-size: 15px;
    }
    .font-collapse-condition .ant-collapse-content-box p {
      font-size: 12px;
    }
  }
}

// @media (min-width: @screen-sm-min) {
// }
// // large screen
// @media (min-width: @screen-lg-min) {
// }

// @media (min-width: @screen-md-min) {
// }

@media (min-width: 750px) and (max-width: 780px) {
  #conditions {
    .title-condition {
      font-size: 40px;
    }

    .text-Condition {
      font-size: 11px;
    }

    .subtitle-condition {
      font-size: 17px;
    }

    .font-collapse-condition .ant-collapse-header {
      font-size: 15px;
    }
    .font-collapse-condition .ant-collapse-content-box p {
      font-size: 12px;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../styles/style-constants.less';
@import '../../styles/color.less';

#cart {
  position: fixed;
  height: 0px;
  top: 0px;
  right: 0;
  width: 56%;
  max-width: 700px;
  z-index: 1000;
  background-color: #f7f2e9;
  overflow: auto;
  transition: 0.4s ease-in-out;

  .base-ads-btn {
    padding-left: unset;
    padding-right: unset;
    width: 100%;
  }
  .no-cart {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 200px auto;

    &__message {
      font-size: 24px;
      line-height: 28px;
    }

    &__button-continue {
      margin-top: 50px;
      border-bottom: 2px solid black;
    }
  }

  .cart {
    &__header {
      align-items: flex-end;
      justify-content: center;
      height: 10%;
    }

    &__title {
      font-family: Frunchy;
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: 43px;
      letter-spacing: 0.03em;
      text-align: left;
    }

    &__button-div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &__close-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }

    &__close-icon {
      width: 25px;
    }

    &__recap {
      width: 80%;
      float: right;
      justify-content: flex-end;
      font-family: Visby CF;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      margin-right: 45px;
    }
    &__content {
      height: 65%;
      overflow-y: scroll;
    }
    &__footer {
      height: 25%;
      padding-top: 20px;
    }
  }

  .recap {
    &__total-price {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 0.5em;

      &--price {
        font-size: 24px;
        font-weight: 600;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
      }
    }

    &__expedition {
      text-align: end;
    }

    &__column {
      padding: 15px 0;
      display: flex;
      align-items: center;

      &--left {
        justify-content: flex-start;
        padding-left: 45px;
      }
      &--right {
        justify-content: flex-end;
        padding-right: 45px;
      }
    }

    &__button-continue {
      background-color: transparent;
      border: none;
      font-family: Visby CF;
      font-size: 14px;
      font-weight: 800;
      line-height: 16px;
      letter-spacing: 0.03em;
      border-bottom: 2px solid #333333;
      padding-bottom: 10px;
      cursor: pointer;
    }

    &__button-command {
      background-color: transparent;
      border: 1px solid #333333;
      border-radius: 50%;
      padding: 0px 30px;
      cursor: pointer;
    }
  }

  .supp {
    padding-right: 10px;
  }

  @media (max-width: @screen-xl) {
    .cart {
      &__header {
        margin-top: 0;
        padding: 10px 0;
        height: 15%;
      }
      &__recap {
        font-size: 16px;
        line-height: 21px;
      }
      &__content {
        height: 55%;
      }
      &__footer {
        height: 30%;
      }
    }
  }

  @media (max-width: @screen-lg) {
    width: 60%;
  }

  @media (max-width: 850px) {
    width: 100%;
    max-width: 100%;

      &__recap {
        font-size: 16px;
        line-height: 21px;
      }
    }

  @media (max-width: @screen-sm) {
    z-index: 100;
    background-color: #f7f2e9;
    width: 100%;

    .cart {
      &__recap {
        width: 80%;
        float: left;
        font-size: 16px;
        line-height: 21px;
        margin-left: 15px;
      }
    }

    .recap {
      margin-left: 15px;

      &__total-price {
        display: flex;
        align-items: center;
        text-align: left;
        margin-bottom: 0.5em;

        &--price {
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 31px;
          letter-spacing: 0em;
        }
      }

      &__expedition {
        text-align: start;
      }

      &__column {
        &--left {
          padding-left: 15px;
        }
        &--right {
          padding-right: 15px;
        }
      }

      &__button-continue {
        color: #333333;
        background-color: transparent;
        font-family: Visby CF;
        font-size: 10px;
        line-height: 12px;
        cursor: pointer;
      }

      &__button-command {
        color: #333333;
        background-color: transparent;
        border: 1px solid #333333;
        padding: 10px 30px;
        border-radius: 50%;
        cursor: pointer;
        font-size: 11px;
        line-height: 11px;
        letter-spacing: 0.03em;
        text-align: center;
      }
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../../../styles/style-constants.less';

#livraison-paiement {
  margin-top: 50px;

  .transporter {
    padding-bottom: 15px;
    width: 90%;
  }


  .ant-radio-wrapper {
    display: flex;

    & span {
      width: 100%;
    }

    .ant-radio {
      width: 16px !important;
    }
  }

  .livraison__choice {
    display: flex;
    align-items: center;
  }

  .livraison__detail {
    text-align: left;
  }

  .detail__transporter label,
  .name {
    margin-left: 10px;
  }

  .detail__fees {
    float: left;
  }

  .detail__logo img {
    width: 90px;
  }

  .mode-paiement__section {
    margin-top: 40px;
  }

  ::placeholder {
    color: #a9a9a9;
    opacity: 1;
  }

  .card-details.ant-col-sm-6 {
    display: block;
  }

  .complementaires__section {
    margin-top: 40px;
  }

  @media (max-width: 768px) {
    .confirmation__section {
      display: none;
    }

    .detail__logo img {
      width: 60px;
    }

    .transporter {
      width: 100%;
    }
  }

  @media (max-width: @screen-sm) {
    .livraison__detail {
      font-size: 11px;
      line-height: 13px;
    }

    .detail__transporter label {
      margin-left: 10px;
      font-size: 11px;
    }

    .card-details label {
      font-size: 12px;
    }

    .card-details input {
      padding: 7px 20px;
    }

    .detail__fees {
      padding-left: 10px;
    }

    textarea {
      width: 100%;
      padding-left: 20px;
      margin-top: 10px;
      font-size: 12px;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../../styles/style-constants.less';

#board {
  padding-left: 40px;

  .board__title {
    font-family: Frunchy;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 58px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
  }

  .board__buttons {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  .board__button {
    border: none;
    text-decoration: underline;
    background-color: transparent;
    font-family: Visby CF;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.03em;
    cursor: pointer;
  }

  .board__recap {
    margin-top: 40px;
  }

  .board__command {
    margin-bottom: 40px;
  }

  .ongoing {
    background-color: #cecece;
  }

  .completed {
    background-color: #51252f;
    color: #fff;
  }

  .command__details {
    display: grid;
    align-items: center;
  }

  .command__details-list {
    list-style-type: none;
    margin-bottom: 0;
    padding: 20px;
    background-color: transparent;
  }

  .command__info {
    font-family: Visby CF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }

  .status {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.03em;
    margin-bottom: 10px;
  }

  .price {
    margin-top: 10px;
    font-weight: 700;
  }

  .command__articles {
    display: flex;
    align-items: center;
    background-color: #fff;

    .ant-row {
      width: 100%;
    }
  }

  .command__images {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    padding: 10px 0px;
    width: auto;
    height: 100%;
    margin-left: 8px;
    cursor: pointer;
  }

  .command__cancel {
    display: flex;
    background-color: #fff;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .cancel__button {
    background-color: transparent;
    border: none;
    font-family: Visby CF;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.03em;
    text-decoration: underline;
    margin-bottom: 20px;
    margin-right: 20px;
    cursor: pointer;
  }

  .cancel__button--responsive {
    display: none;
  }

  @media (max-width: @screen-lg) {
    .board__title {
      font-size: 38px;
      line-height: 43px;
    }

    .board__button {
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0em;
    }

    .command__info {
      font-size: 12px;
      line-height: 16px;
    }

    .status {
      font-size: 18px;
      line-height: 22px;
    }

    .price {
      margin-top: 10px;
      font-weight: 700;
    }

    .cancel__button {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 30px;
    }
  }

  @media (max-width: @screen-md) {
    .board__title {
      font-size: 28px;
      line-height: 33px;
    }

    .board__button {
      font-size: 12px;
      line-height: 11px;
      letter-spacing: 0em;
    }

    .command__info {
      font-size: 10px;
      line-height: 12px;
    }

    .status {
      font-size: 14px;
      line-height: 17px;
    }

    .price {
      font-size: 12px;
      margin-top: 10px;
      font-weight: 700;
    }

    .cancel__button {
      font-size: 9px;
      line-height: 12px;
      margin-right: 10px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: @screen-sm) {
    padding-left: 0;

    .navbar-toggle__button {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }

    .navbar-toggle__button img {
      width: 20px;
      padding-bottom: 5px;
    }

    .board__title {
      font-size: 36px;
      line-height: 24px;
      margin-bottom: 20px;
    }

    .board__button {
      font-size: 14px;
      line-height: 16px;
    }

    .board__buttons {
      display: grid;
      grid-template-columns: max-content;
    }

    .board__logout {
      text-align: left;
      margin-top: 10px;
    }

    .command__cancel {
      display: none;
    }

    .command__info {
      font-size: 16px;
      line-height: 21px;
    }

    .status {
      font-size: 24px;
      line-height: 27px;
    }

    .cancel__button {
      font-size: 14px;
      line-height: 16px;
    }

    .cancel__button--responsive {
      position: absolute;
      display: flex;
      right: 0;
      bottom: 0;
      background-color: transparent;
      border: none;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
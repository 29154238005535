#availabilitie {
  font-family: Visby CF;
  font-style: normal;
  font-size: 16px;
  line-height: 90%;
  letter-spacing: 0.03em;

  .name {
    width: 50%;
  }

  .description {
    width: 65%;
  }

  .calendar {
    display: flex;
    margin: auto;
    box-shadow: '0 2px 4px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 8%)';

    box-shadow: rgba(67, 117, 151, 0) 0px 3px 7px 0px;
  }

  .calendar-title {
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 21px;
    letter-spacing: 0.03em;
    color: #333333;
    margin-top: 50px !important;
    margin-bottom: 40px !important;
  }

  .calendar-header-date {
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;

    span {
      line-height: 14px;
      letter-spacing: 3%;
      text-align: center;
    }
  }

  .calendar-header-hours {
    font-weight: normal;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .slick-slider {
    max-width: 100%;
    background-color: #fff;
  }
  .slick-list {
    min-height: 300px;
  }

  .k-hour {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .calendar-header-arrow {
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: #fff;
    padding-top: 40px;
  }

  .rdv-container {
    width: 85%;
    margin: auto;
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../../styles/style-constants.less';

#payment-section {
  .payment__button {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  .payment__back {
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.03em;
    text-decoration: underline;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .payment-tabs {
    margin: auto 18px;
  }

  .payment-collapse {
    // border: 1px solid #b7b7b7 !important;
    // background-color: #ffffff !important;

    .ant-collapse-header {
      padding-bottom: 25px !important;
    }

    .stripe-payment-panel {
      padding-left: 35px;
      padding-right: 35px;
      width: 100%;
    }

    .stripe-button {
      margin-top: 18px;
    }

    .stripe-form.is-only {
      height: 250px;
      display: flex;
      align-items: center;
    }

    .content {
      width: 100%;
    }
  }

  [data-theme='compact'] .payment-collapse .payment-collapse-panel,
  .payment-collapse .payment-collapse-panel {
    margin-bottom: 24px;
    overflow: hidden;
    // background: #f7f7f7;
    background-color: #ffffff !important;
    border: 1px solid #e4dfdf !important;

    border: 0px;
    border-radius: 2px;
  }

  @media (max-width: @screen-lg) {
    .payment__back {
      font-size: 18px;
      line-height: 21px;
    }
  }

  @media (max-width: @screen-sm) {
    .payment__back {
      font-size: 16px;
      line-height: 19px;
    }
  }

  @media (max-width: @screen-xs) {
    .payment__back {
      font-size: 14px;
      line-height: 16px;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@font-face {
  font-family: 'Everty Glorial';
  font-style: normal;
  font-weight: normal;
  src: local('Everty Glorial'), url('./Everty-Glorial.ttf') format('truetype');
}

@font-face {
  font-family: 'Frunchy';
  font-style: normal;
  font-weight: normal;
  src: local('Frunchy'), url('./Frunchy.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  src: local('Everty Glorial'), url('./Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 1000000;
}
#cookiescript_injected {
  border-radius: 10px;
}
#cookiescript_injected {
  transition: width 200ms 600ms;
}
#cookiescript_injected {
  bottom: 20px;
  left: 20px;
  position: fixed;
  padding: 15px 20px 15px 20px;
  width: 300px;
  text-align: left;
  max-height: 85%;
  overflow-y: auto;
  max-width: calc(100% - 40px);
}
#cookiescript_injected {
  background-color: #2d2d2d;
  z-index: 999997;
  opacity: 1;
  font-size: 14px;
  font-weight: normal;
  font-family: 'Open Sans', Arial, 'Trebuchet MS', 'Segoe UI', 'Helvetica',
    sans-serif;
  box-shadow: 1px 2px 8px 0 rgb(0 0 0 / 35%);
  color: #ffffff;
  box-sizing: border-box;
}

#cookiescript_header {
  padding: 14px 0 12px;
  text-align: left;
  margin: 0;
}
#cookiescript_header {
  background-color: transparent;
  z-index: 999998;
  color: #ffffff;
  font-size: 17px;
  line-height: 1.3;
  font-weight: 600;
  letter-spacing: 0.4px;
  opacity: 1;
}
#cookiescript_description {
  margin: 0 0 12px;
}
#cookiescript_description {
  color: #ffffff;
  font-size: 11px;
  letter-spacing: 0.3px;
  line-height: 1.8;
  font-weight: 400;
  opacity: 0.85;
  text-align: justify;
}
#cookiescript_buttons {
  justify-content: space-between;
  margin: 0 -5px 0 -5px;
  flex-wrap: wrap;
}
#cookiescript_buttons {
  display: flex;
  flex-direction: row;
  font-weight: 700;
}
#cookiescript_accept,
#cookiescript_save,
#cookiescript_reject {
  flex-grow: 1;
  padding: 0 7px;
  margin: 0 5px 10px 5px;
}
#cookiescript_accept,
#cookiescript_reject,
#cookiescript_save {
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;
  min-width: 103px;
}
#cookiescript_save {
  border: 0;
  transition: all 0.25s ease 0s;
  background-color: #e6ceb5;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 11px;
  text-align: center;
  line-height: 3.3;
  letter-spacing: 0.4px;
}
#cookiescript_accept:hover,
#cookiescript_save:hover {
  background-color: black;
}
#cookiescript_accept,
#cookiescript_save,
#cookiescript_reject {
  flex-grow: 1;
  padding: 0 7px;
  margin: 0 5px 10px 5px;
}
#cookiescript_accept,
#cookiescript_reject,
#cookiescript_save {
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;
  min-width: 103px;
}
#cookiescript_accept,
#cookiescript_save {
  border: 0;
  transition: all 0.25s ease 0s;
  background-color: #e6ceb5;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 11px;
  text-align: center;
  line-height: 3.3;
  letter-spacing: 0.4px;
  font-family: 'Trebuchet MS', 'Arial', sans-serif;
}
#cookiescript_accept,
#cookiescript_save,
#cookiescript_reject {
  flex-grow: 1;
  padding: 0 7px;
  margin: 0 5px 10px 5px;
}
#cookiescript_accept,
#cookiescript_reject,
#cookiescript_save {
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;
  min-width: 103px;
}
#cookiescript_reject {
  border: 1px solid #ffffff;
  text-align: center;
  line-height: 3;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  color: #ffffff;
  background: #2d2d2d;
}
#cookiescript_manage_wrap {
  margin: 0 0 11px 0;
}
#cookiescript_manage_wrap {
  text-transform: uppercase;
  text-align: center;
  font-size: 11px;
  letter-spacing: 0.1px;
  font-weight: 500;
  outline: none;
}
#cookiescript_close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 12px;
  line-height: 13px;
  cursor: pointer;
  color: #ffffff;
  height: 15px;
  text-decoration: underline;
  margin: 0;
  padding: 0;
  overflow: hidden;
  letter-spacing: 0;
  font-family: 'Trebuchet MS', 'Arial', sans-serif;
  font-weight: 100;
  opacity: 0.85;
  z-index: 999999;
}
#cookiescript_close-parametre {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  line-height: 13px;
  cursor: pointer;
  color: #ffffff;
  background-color: #e6ceb5;
  border: none !important;
  height: 25px;
  width: 25px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  letter-spacing: 0;
  font-family: 'Trebuchet MS', 'Arial', sans-serif;
  font-weight: 100;
  opacity: 0.85;
  z-index: 999999;
}
.cookieText {
  font-family: 'Trebuchet MS', 'Arial', sans-serif;
  font-size: medium;
  color: black;
  text-align: justify;
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
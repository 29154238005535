@import '../../../../styles/style-constants.less';

.partner-section {
  font-family: Visby CF;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.03em;
  text-align: center;

  .partner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .partner-name {
    margin: 0px;
    display: block;
    height: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .logo-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  .partner-wrapper img {
    width: 200px;
    object-fit: contain;
    max-height: 150px;
    max-width: 100%;
  }

  .header-h3-text-normal {
    margin-bottom: 50px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;

    .header-h3-text-normal {
      margin-bottom: 20px;
    }

    .partner-name {
      margin: 0px;
      display: block;
      height: 100%;
      margin-top: 10px;
      margin-bottom: 20px;
    }

  }

  @media (max-width: @screen-sm) {
    .header-h3-text-normal {
      margin-bottom: 20px;
    }

    .partner-wrapper img {
      width: 150px;
      object-fit: contain;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
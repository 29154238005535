@import '../../../styles/style-constants.less';

#resume-commande {
  margin-top: 70px;

  .amount {
    flex-grow: 1;
    text-align: right;
    align-items: baseline;

    .ant-col.ant-form-item-control .ant-form-item-control-input {
      align-items: start;
    }
  }

  .line-amount {
    display: flex;
    width: 100%;
  }

  .command-card {
    margin-top: 20px;
    border-bottom: solid 0.5px #000000;
    padding: 20px 0;
  }

  .command-card__image-column {
    display: flex;
    align-items: center;
  }

  .command-card__image {
    width: 100px;
    justify-content: center;
  }

  .command-card__details {
    list-style-type: none;
    line-height: 27px;
    padding-left: 20px;
  }

  .command-card__name {
    font-weight: 400;
    font-size: 24px;
    margin: 10px 0;
  }

  .command-card__price {
    font-weight: 700;
    font-size: 24px;
    margin: 10px 0;
  }

  .remove-button {
    position: absolute;
    bottom: 0;
    right: 20px;
  }

  .remove-text {
    float: right;
    margin-right: 15px;
    margin-top: 17px;
  }

  .remove-button img {
    width: 16px;
    float: right;
    padding-top: 3px;
    cursor: pointer;
    margin-top: 17px;
  }

  .command-recap {
    margin-top: 40px;
    color: #303030;
  }

  .command-recap__span {
    float: right;
    font-weight: 600;
  }

  .detail-amount {
    float: right;
    font-weight: 500;
  }

  .command-recap__subtotal {
    margin-bottom: 10px;
  }

  .command-recap__expedition {
    display: flex;

    margin-bottom: 30px;
  }

  .command-recap__total {
    margin-bottom: 25px;
    line-height: 30px;
  }

  .command-recap__tot {
    font-size: 24px;
  }

  .promo-code {
    width: 65%;
    text-align: center;
  }

  .command-recap__apply {
    font-weight: 700;
    font-size: 14px;
    text-decoration: underline;
  }

  .apply-button {
    background-color: transparent;
    border: none;
    float: right;
    cursor: pointer;
  }

  .secure-data {
    margin: 60px 0;
  }

  .securedata__logo {
    margin-bottom: 15px;
    width: 36px;
  }

  .command-recap__promo {
    display: flex;
    flex-direction: row;

    .input-row {
      width: 100%;
      input {
        width: 80%;
      }
    }
  }

  @media (max-width: @screen-lg) {
    .command-card__details {
      list-style-type: none;
      line-height: 27px;
      letter-spacing: 0;
    }

    .command-card__name {
      font-size: 20px;
      line-height: 22px;
    }

    .command-card__price {
      font-size: 20px;
      line-height: 22px;
    }
  }

  @media (max-width: 768px) {
    margin-top: 0;

    .secure-data {
      position: static;
      margin-top: 40px;
      display: inline-block;
    }

    .securedata__logo {
      margin-bottom: 15px;
      width: 54px;
    }

    .resume-commande__title {
      font-size: 30px;
      font-weight: 400;
      line-height: 24px;
    }

    .resume-commande__variable {
      font-size: 11px;
      font-weight: 400;
      line-height: 13px;
    }

    .promo-code input {
      width: 55%;
      text-align: center;
      padding: 20px 0;
    }

    .command-card__image {
      width: 150px;
      justify-content: center;
    }

    .command-card__name {
      font-size: 24px;
    }

    .command-card__price {
      font-size: 24px;
    }
  }
  .signin-error {
    height: 100%;
    display: flex;
    align-items: center;

    &__text {
      font-family: Visby CF;
      font-size: 18px;
      line-height: 130%;
      color: #ff4d4f;
    }
  }

  @media (max-width: @screen-sm) {
    .command-card__details {
      padding-left: 0;
    }

    .command-card__name {
      font-size: 14px;
    }

    .command-card__price {
      font-size: 14px;
    }

    .command-card__image {
      width: 100px;
      justify-content: center;
    }

    .remove-text {
      margin-top: 11px;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../styles/style-constants.less';

.video-content-area {
  margin-top: 35px;
  height: 195px;
  width: 100%;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%;
}

@media (min-width: @screen-md-min) {
  .video-content-area {
    height: 618px;
    width: 100%;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    max-height: 100%;
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
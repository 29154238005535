@import '../../styles/style-constants.less';
@import '../../styles/color.less';

#contact {
  margin-top: 80px;

  input :focus {
    background-color: yellow;
    border-color: yellowgreen;
  }

  .title-container {
    h1 {
      font-family: Everty Glorial;
      font-style: normal;
      font-weight: normal;
      font-size: 100px;
      line-height: 80.5%;

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.03em;

      margin: 0px !important;
      color: #4c282e;
      z-index: 1;
      position: relative;
    }

    display: flex;
    justify-content: center;
    margin-bottom: -36px;
    z-index: 1;
    margin-bottom: -47px;
  }

  .banner-img-home {
    z-index: 0;
  }

  .form-title-wrapper {
    margin-top: 20px;
  }

  .form-section {
    margin-top: 118px;

    h1 {
      margin: 0 !important;
    }

    .form-title {
      font-family: Frunchy;
      font-size: 100px;
      font-style: normal;
      font-weight: 400;
      line-height: 90px;
      letter-spacing: 0.03em;
      text-align: left;
    }

    .form-title-italick {
      font-family: Everty Glorial;
      font-size: 100px;
      font-style: normal;
      font-weight: 400;
      line-height: 90px;
      letter-spacing: 0.03em;
      text-align: left;
    }
  }

  .contact-maps {
    width: 100%;
    height: auto;
  }

  .only-mobile {
    display: none;
  }

  .form-input {
    font-family: Visby CF !important;
    font-style: italic !important;
    font-weight: normal !important;
    font-size: 20px !important;
    line-height: 130% !important;
    color: #787878 !important;
    padding-left: 0px;
    border: none;
    // background-color: transparent;

    outline: none;
    background-color: transparent;

    border-bottom: 0.7px solid #443f3f !important;
    border-radius: 0px;
    padding: 6.5px 11px;

    // .PhoneInputInput {
    //   border: none;
    //   background-color: transparent;
    // }
  }

  .form-input.PhoneInput {
    display: flex;
    input,
    .PhoneInputInput:focus {
      border: none !important;
      outline: none;

      background-color: transparent;
    }
  }

  .form-input::placeholder {
    color: #787878 !important;
  }

  .contact-form {
    margin-top: 19px;
    width: 80%;
  }
  .newsletter-checkbox{
    margin-bottom: 1em;
    span{
      font-size: 21px
    }
  }

  .success-message {
    font-family: Frunchy;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 100%;
    letter-spacing: 0.03em;
    color: #333333;

    max-width: 413px;
    margin-top: 70px;
  }

  @media (max-width: @screen-xs-max) {
    margin-top: 80px;
    margin-bottom: 30px;

    .banner.container {
      max-width: 100%;
      padding: 0px;
    }

    .title-container {
      h1 {
        font-size: 30px;
      }
    }

    .form-title {
      font-size: 30px !important;
      line-height: 95.5% !important;
    }

    .form-title-italick {
      font-size: 35px !important;
      line-height: 95.5% !important;
    }

    .form-section {
      margin-top: 0px;
    }

    .title-container {
      margin-bottom: -14px;
    }

    .form-title-wrapper {
      margin-top: 20px;
    }

    .contact-form {
      margin-top: 0px;
      width: 100%;
    }

    .form-input {
      font-family: Visby CF;
      font-style: normal;
      font-weight: normal;
      font-size: 14px !important;
      line-height: 130%;
      color: #aaaaaa;
      padding: 5px 5px 5px 0px;
    }

    .contact-maps {
      margin-top: 30px;
    }

    .only-mobile {
      display: block;
    }
  }

  .contact-buton {
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 113%;

    color: #333333;
    margin-top: 40px;
    /* Gray 1 */

    border: 1px solid #333333;
    box-sizing: border-box;

    border-radius: 50%;
    cursor: pointer;
    width: 170px;
    height: 53px;
    margin-top: 45px;
    background-color: transparent;
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
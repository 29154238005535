@import '../../styles/style-constants.less';

.product-card:hover {
  opacity: 0.75;
  transform: scale(1.03);
}

.product-card {
  width: 160px;
  font-family: Visby CF;
  color: #333333;
  font-style: normal;
  font-weight: 400;
  transition: 0.3s ease;
  cursor: pointer;
  margin: 20px auto;
  align-items: unset;

  p{
    margin-bottom: 0;
  }

  .product-card-detail {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 12px;
    align-items: center;
  }

  .product-card-amount {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-align: center;
    float: right;

    strike {
      font-size: 12px;
    }
  }

  .advantage-slash {
    font-size: 45px;
    font-weight: 100;
    line-height: 81px;
    letter-spacing: 0.03em;
    text-align: left;
  }

  .advantage-main-text {
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.03em;
    text-align: left;
    width: 115px;
    padding-top: 3rem;
  }

  .product-card-trybtn {
    margin: 0;
    text-align: center;
    text-decoration: underline;
    font-style: normal;
    font-weight: 600;
    white-space: nowrap ;
    margin: auto;
    padding: 8px 0;
  }

  .anticon svg {
    width: 16px;
    height: 16px;
    margin: 10px;
  }

  .product-card-img-wrapper {
    background-color: unset;
    position: relative;
    width: 100% !important;
    height: 100%;
    
  }

  .product-card-icons {
    position: absolute;
    top: 0;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .product-card-view-icons {
    position: absolute;
    bottom: 0;
    margin: 10px;
    bottom: 3em;
    left: 0px;
  }

  .produit-advantage {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-top: 1rem;
  }


  @media (max-width: @screen-xs-max) {

    
  }
}

.highlightingItem {
  background-color: white;
  width: 100%;
  .product-card-img-wrapper {
    height: 90%;
    display: flex;
    align-items: center;

    img {
      max-height: 90%;
      width: auto;
      height: 75%;
      margin: 0 auto;
      padding: 0px;
      max-width: 100%;
    }
  }

  .product-card-icons {
    right: 15%;
    margin: 0px;
    margin-top: 7%;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .product-card-detail {
    padding-right: 15%;
    padding-left: 15%;
  }

  
}


.product-card-text {
  font-size: 12px;
  padding-top: 0px;
  padding-bottom: 0px;
  text-align: start;
}

.product-card-amount {
  font-size: 11px;
  text-align: right;
  white-space: nowrap;
  margin-bottom: 0;

  strike {
    font-size: 9px;
  }
}

.product-card-img-wrapper > a {
  display: inline-block;
  height: 211px !important;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-top: 0px;
  overflow: hidden;
  margin: unset;

  img{
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 0 ;
  }
}

.produit-advantage {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  margin: 0.5rem auto;
}


.product-card-name {
  font-size: 10px;
}
@media only screen and (min-width: @screen-md) {
  .product-card{
    width: 230px;
  }
  .product-card-img-wrapper > a{
    height: 330px !important;
  }
  .product-card-icons{
    justify-content: flex-end !important;
    transform: translateY(-100%);
  }
  .product-card-text{
    font-size: 16px;
  }
}
@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
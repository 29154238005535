.user_profil {
  ul {
    padding: 0;
    list-style: none;
    background: #f2f2f2;
  }

  ul li {
    display: block;
    position: relative;
    line-height: 21px;
    text-align: left;
  }

  ul li a {
    display: block;
    padding: 8px 25px;
    color: #333;
    text-decoration: none;
  }

  ul li a:hover {
    color: #fff;
    background: #939393;
  }

  ul li ul.dropdown {
    width: max-content;
    border-radius: 5px;
    min-width: 100%;
    /* Set width of the dropdown */
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    // display: none;
    position: absolute;
    z-index: 999;
    left: 0;
    opacity: 0;
    transition: all 0.5s ease;
  }

  ul li:hover ul.dropdown {
    // display: block;
    transform: translateY(0);
    opacity: 1;
    /* Display the dropdown */
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import "../../styles/style-constants.less";

.header-h1-text-normal {
  font-family: "Frunchy";
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24.15px;
  letter-spacing: 0.02em;
  margin-block: 0;
  color: #333333;

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.header-h1-text-italic {
  font-family: "Everty Glorial";
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24.15px;
  letter-spacing: 0.02em;
  margin-block: 0;
  color: #333333;

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.center-text {
  text-align: center;
}

.header-h3-text-normal {
  font-family: Visby CF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.03em;
  text-align: left;

  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media (min-width: @screen-md-min) {
  .header-h1-text-normal {
    font-size: 100px;
    line-height: 66.5px;
    letter-spacing: 0.03em;
  }

  .header-h1-text-italic {
    font-family: "Everty Glorial";
    font-size: 6.25rem;
    line-height: 80.5px;
    letter-spacing: 0.03rem;
  }

  .header-h3-text-normal {
    font-family: Visby CF;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.03em;
    text-align: left;

    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
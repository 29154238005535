@import '../../styles/style-constants.less';

#appointment {
  .header-h1-text-italic {
    position: relative;
    z-index: 1;
    margin-top: 60px;
    // margin-bottom: -50px;
    color: #4c282e;
    font-weight: 400;
  }

  .appointment-banner__image {
    position: relative;
    width: 100%;
  }

  .appointment-banner__image img {
    width: 100%;
  }

  .appoint-offers__caption {
    font-family: 'Frunchy';
    font-weight: 400;
    font-size: 80px;
    line-height: 90px;
    width: 80%;
    margin-top: 40px;
    color: #333333;
  }

  .appointment-offers {
    margin: 0 auto;
    font-family: Visby CF;
    color: #333333;
    letter-spacing: 3%;
    background-color: transparent;
  }

  .rdv-container {
    width: 90%;
    margin: auto;
  }

  .ant-collapse-header {
    margin: 30px 0;
    font-weight: 500;
    font-size: 24px;
    color: #000000;
  }

  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    right: 50px;
    bottom: 23px;
    position: absolute;
    font-size: 20px;
  }

  .appointment-offers__sub {
    margin: 30px 0;
  }

  .appointment-offers__price {
    font-weight: 500;
    font-size: 24px;
    line-height: 90%;
  }

  .ant-col.ant-col-xl-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-col.ant-col-xs-10.ant-col-xl-6 {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .appointment-offers__button {
    font-size: 14px;
    font-weight: 300;
    border: 1px solid #4f4f4f;
    border-radius: 50%;
    background-color: transparent;
    padding: 12px 27px 15px 27px;
    margin: 0 auto;
    cursor: pointer;
  }

  .appointItem {
    .name {
      width: 70%;
    }
  }
}

@media (max-width: 1024px) {
  #appointment {
    .ant-collapse-header {
      padding-bottom: 0;
    }

    .ant-col.ant-col-xs10.ant-col-xl-10 {
      display: block;
    }

    .appointment-offers__button {
      margin: 10px 0;
    }
  }
}

@media (max-width: 1024px) {
  #appointment {
    .header-h1-text-italic {
      color: #4c282e;
      // font-size: 45px;
    }
  }
}

@media (max-width: 766px) {
  #appointment {
    .header-h1-text-italic {
      color: #4c282e;
      // font-size: 45px;
      // top: 20px;
    }

    .appointment-banner__image {
      margin-top: 50px;
    }

    .ant-collapse-header {
      margin: 10px 0;
      font-size: 16px;
      color: #000000;
      width: 90%;
    }

    .ant-collapse-icon-position-right
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-arrow {
      right: -15px;
      bottom: 16px;
      position: absolute;
      font-size: 20px;
    }

    .appointment-offers__description {
      font-size: 11px;
      margin-top: 15px;
      width: 95%;
    }

    .appointment-offers__price {
      font-size: 16px;
      line-height: 14px;
    }

    .ant-col.ant-col-xl-2 {
      align-items: flex-start;
      margin-top: 20px;
    }

    .ant-col.ant-col-xl-10 {
      justify-content: left;
    }

    .appointment-offers__button {
      margin: 10px 0;
      font-size: 11px;
      line-height: 13px;
      padding: 10px 15px;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../styles/style-constants.less';
#loyality {
  margin-top: 83px;

  .fidelite-header-section {
    font-family: Frunchy;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #333333;

    max-width: 280px;
    margin-bottom: 10px;
  }

  .yellow-background-color {
    background-color: #faf5ed;
  }

  .slight-gray-background-color {
    background-color: #ece6e7;
  }

  .gray-background-color {
    background-color: #dadada;
  }

  .fidelite-intro-section {
    font-family: Visby CF;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px;
    letter-spacing: 0.03em;
    text-align: left;

    color: black;
  }

  .fidelite-recompense-section {
    position: relative;
    padding-top: 40px;
  }

  .loyality-table-title {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .loyality-table-title > p {
    margin-right: 10px;
    margin-bottom: 0;
  }

  td {
    padding-left: 40px;
    padding-bottom: 40px;
    padding-right: 40px;
  }

  .loyality-text-emphasis {
    font-family: Visby CF;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.03em;
    text-align: left;
  }

  .inscrire-button-wrapper {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
  }

  .loyality-table-section {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 82px calc(100% - 82px);
    grid-template-rows: repeat(5, auto);
  }

  .card-title {
    display: flex;
    position: relative;
    align-items: flex-end;
    justify-content: center;
    font-size: 16px;
    line-height: 21px;
    font-family: Visby CF;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.03em;
    text-align: left;
    color: #000;
    margin-top: 15px;
  }

  .card-title img {
    padding-left: 10px;
  }

  .table {
    margin-top: 50px;
  }

  td {
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    font-family: Visby CF;
    font-weight: 300;
    letter-spacing: 0.03em;
    text-align: left;
    font-size: 11px;
    line-height: 14px;
    height: 100%;
  }

  td > ul {
    padding-left: 0;
    list-style-type: none;
  }

  td > ul > li {
    padding-bottom: 30px;
  }

  .parrainage-content-section {
    width: 108%;
    margin-left: -4%;

    padding-left: 4%;
    padding-right: 4%;
    padding-bottom: 40px;

    margin-top: calc(36% + 30px);
    margin-bottom: 50px;
    background-color: #e6ceb5;

    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: repeat(4, auto);

    row-gap: 20px;

    position: relative;
  }

  .parrainage-text {
    font-family: Visby CF;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px;
    letter-spacing: 0.03em;
    text-align: left;
    color: black;
  }

  .linked-path-group {
    display: none;
  }

  .parrainage-content-row1-col1 {
    font-family: Visby CF;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-align: left;
    color: black;

    margin-bottom: 20px;

    grid-row-start: 2;
    grid-row-end: 3;

    grid-column-start: 1;
    grid-column-end: 2;
  }

  .parrainage-content-row1-col2 {
    grid-row-start: 1;
    grid-row-end: 2;

    grid-column-start: 1;
    grid-column-end: 2;

    width: 100%;
    height: 280px;
    overflow-y: hidden;

    margin-top: -36%;
    z-index: 10;
  }

  .parrainage-content-row1-col2 > img {
    width: 100%;
    height: auto;
    min-height: 323px;
    object-fit: cover;
  }

  .parrainage-text-block {
    display: block;
  }

  .parrainage-section {
    margin-top: 50px;
  }

  .sublime-signature-wrapper {
    position: absolute;
    z-index: 10;
    top: -25%;
    right: 0;
  }

  @media (min-width: @screen-md-min) {
    margin-top: 55px;

    .sublime-signature-wrapper {
      top: -24%;
      right: 3%;
    }

    .parrainage-section {
      margin-top: 0;
    }

    .parrainage-text-block {
      display: block;
      margin-bottom: 15px;
      margin-top: 15px;
    }

    .linked-path-group {
      display: block;
      position: absolute;

      width: 54%;

      top: -7%;
      right: 40px;
    }

    .linked-path-group > img {
      width: 100%;
      height: auto;
    }

    .parrainage-content-row1-col1 {
      font-family: Visby CF;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 31px;
      letter-spacing: 0.03em;
      text-align: left;
      color: black;

      max-width: 371px;

      grid-row-start: 1;
      grid-row-end: 2;

      grid-column-start: 1;
      grid-column-end: 2;
    }

    .parrainage-content-row2-col1 {
      max-width: 371px;

      grid-row-start: 2;
      grid-row-end: 3;

      grid-column-start: 1;
      grid-column-end: 2;
    }

    .parrainage-content-row2-col2 {
      max-width: 344px;

      grid-row-start: 2;
      grid-row-end: 3;

      grid-column-start: 2;
      grid-column-end: 3;
    }

    .parrainage-content-row1-col2 {
      grid-row-start: 1;
      grid-row-end: 2;

      grid-column-start: 2;
      grid-column-end: 3;

      width: 100%;
      height: 323px;
      overflow-y: hidden;

      margin-top: -36%;
      z-index: 10;
    }

    .parrainage-content-row1-col2 > img {
      width: 100%;
      height: auto;
      min-height: 323px;
    }

    .parrainage-text {
      font-family: Visby CF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.03em;
      text-align: left;
      color: black;
    }

    .parrainage-content-section {
      width: 100%;
      margin-left: 0;

      margin-top: 40px;
      margin-bottom: 100px;

      padding-top: 60px;
      padding-bottom: 60px;
      padding-left: 100px;
      padding-right: 100px;

      display: grid;
      grid-template-columns: 42% 50%;
      grid-template-rows: repeat(2, auto);

      row-gap: 30px;
      column-gap: 8%;

      position: relative;
    }

    .inscrire-button-wrapper {
      padding-top: 40px;
    }

    .loyality-text-emphasis {
      font-family: Visby CF;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0.03em;
      text-align: left;
    }

    .loyality-table-section {
      margin-top: 50px;
      margin-bottom: 50px;
      display: grid;
      grid-template-columns: repeat(4, auto);
      grid-template-rows: repeat(5, auto);
    }

    .table {
      margin-top: 60px;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
      width: 85%;
      float: right;
      margin-bottom: 70px;
    }

    .card-title img {
      position: absolute;
      bottom: 0;
    }

    td {
      padding-left: 40px;
      padding-bottom: 40px;
      padding-right: 40px;
      font-family: Visby CF;
      font-style: normal;
      font-weight: 300;
      letter-spacing: 0.03em;
      text-align: left;
      font-size: 16px;
      line-height: 21px;
      height: 100%;
    }

    td > ul {
      padding-left: 0;
      list-style-type: none;
    }

    td > ul > li {
      padding-bottom: 30px;
    }

    .text-big {
      font-size: 24px;
      line-height: 31px;
      position: relative;
      top: 15px;
    }

    .fidelite-recompense-section {
      padding-top: 70px;
    }

    .fidelite-header-section {
      font-family: Frunchy;
      font-size: 85px;
      font-style: normal;
      font-weight: 400;
      line-height: 80px;
      letter-spacing: 0.03em;
      text-align: left;

      max-width: 500px;
      margin-bottom: 30px;
    }

    .fidelite-intro-section {
      font-family: Visby CF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.03em;
      text-align: left;

      max-width: 436px;
    }
  }

  @media (min-width: @screen-xl-min) {
    .sublime-signature-wrapper {
      top: -20%;
      right: 20%;
    }

    .fidelite-header-section {
      font-family: Frunchy;
      font-size: 100px;
      font-style: normal;
      font-weight: 400;
      line-height: 90px;
      letter-spacing: 0.03em;
      text-align: left;

      max-width: 600px;
      margin-bottom: 30px;
    }

    .linked-path-group {
      display: block;
      position: absolute;

      width: 54%;

      top: -29%;
      right: 40px;
    }

    .linked-path-group > img {
      width: 100%;
      height: auto;
    }

    .data-cell {
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 40px;

      font-family: Visby CF;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 21px;
      letter-spacing: 0.03em;
      text-align: left;

      height: 100%;
    }

    .col-title-cell {
      padding-top: 40px;
      padding-bottom: 40px;

      font-family: Visby CF;
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 31px;
      letter-spacing: 0.03em;
      text-align: left;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import "../../styles/style-constants.less";
#the-creator {
  margin-top: 83px;
  margin-bottom: 50px;

  .de-moi-section-title {
    font-family: Frunchy;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.02em;
    text-align: left;
    margin: 0;
    margin-block: 0;
    color: #4c282e;

    grid-row-start: 1;
    grid-row-end: 2;

    grid-column-start: 1;
    grid-column-end: 2;

    justify-self: start;
  }

  .de-moi-section-text {
    font-family: Visby CF;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px;
    letter-spacing: 0.03em;
    text-align: left;
    color: black;
    margin-block: 0;
    margin: 0;
  }

  .de-moi-section-text-emphasis {
    font-family: Visby CF;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.03em;
    text-align: left;
    color: black;
  }

  .de-moi-section {
    margin-top: 40px;
    display: grid;
    grid-template-rows: repeat(4, auto);
    grid-template-columns: 100%;
    row-gap: 20px;
  }

  .de-moi-section-img {
    grid-row-start: 3;
    grid-row-end: 4;

    grid-column-start: 1;
    grid-column-end: 2;

    width: 100%;
  }

  .de-moi-description {
    font-family: Visby CF;
    font-size: 11px;
    font-style: normal;
    line-height: 14px;
    letter-spacing: .03em;
    text-align: left;
    color: #000;
    width: 100%;
  }

  .short-menu-section {
    margin-top: 25px;
    width: 100%;

    background-color: #4c282e;
    padding-top: 60px;
    padding-bottom: 60px;

    padding-left: 20px;
    padding-right: 20px;

    display: flex;
    flex-flow: column nowrap;
  }

  .short-menu-card-wrapper {
    margin-bottom: 30px;
  }

  .short-menu-card-title {
    font-family: Frunchy;
    font-size: 38px;
    line-height: 34px;
    letter-spacing: 0.03em;
    text-align: left;
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 15px;
    color: white;
  }

  .short-menu-card-content a {
    font-family: Visby CF;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: .03em;
    text-align: left;
    color: white;
    text-decoration: underline;
  }

  .short-menu-card-content li {
    color: white;
    margin: 20px 0;
  }

  .short-menu-card-content ul {
    padding-left: 10px;
  }

  .short-menu-card-content span {
    font-family: Visby CF;
    font-size: 12px;
    font-style: normal;
    font-weight: 100;
    line-height: 15px;
    letter-spacing: .02em;
  }

  @media (min-width: @screen-md-min) {
    margin-top: 55px;
    margin-bottom: 20px;

    .short-menu-card-wrapper {
      max-width: 280px;
      margin-bottom: 0;
    }

    .short-menu-section {
      margin-top: 80px;
      padding-top: 70px;
      padding-bottom: 70px;
      padding-left: 80px;
      padding-right: 80px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
    }

    .short-menu-card-title {
      font-family: Frunchy;
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
      letter-spacing: 0.03em;
      text-align: left;
      margin: 0;
      margin-block-start: 0;
      margin-block-end: 15px;
    }

    .de-moi-section {
      margin-top: 65px;
      display: grid;
      grid-template-rows: repeat(3, auto);
      grid-template-columns: repeat(2, auto);
      row-gap: 0;
      column-gap: 60px;
      width: fit-content;
    }

    .de-moi-description {
      font-family: Visby CF;
      font-size: 16px;
      font-style: normal;
      line-height: 21px;
      letter-spacing: .03em;
      text-align: left;
      color: #000;
      width: 100%;
    }

    .de-moi-section-text-emphasis {
      font-family: Visby CF;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0.03em;
      text-align: left;
      color: black;
    }

    .de-moi-section-text {
      font-family: Visby CF;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 21px;
      letter-spacing: 0.03em;
      text-align: left;
      color: black;

      max-width: 463px;
    }

    .de-moi-section-title {
      font-family: Everty Glorial;
      font-size: 75px;
      font-style: normal;
      font-weight: 400;
      line-height: 67px;
      letter-spacing: 0.03em;
      text-align: left;
      margin: 0;
      margin-block: 0;
      color: black;

      grid-row-start: 1;
      grid-row-end: 2;

      grid-column-start: 1;
      grid-column-end: 3;

      justify-self: start;
      margin-bottom: -1rem;
    }

    .de-moi-section-img {
      grid-row-start: 2;
      grid-row-end: 4;

      grid-column-start: 1;
      grid-column-end: 2;

      justify-self: start;
      height: auto;
      max-height: 512px;
      width: auto;
    }

    .short-menu-card-content a {
      font-family: Visby CF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: .03em;
      text-align: left;
      color: white;
      text-decoration: underline;
    }

    .short-menu-card-content li {
      color: white;
      margin: 20px 0;
    }

    .short-menu-card-content ul {
      padding-left: 10px;
    }

    .short-menu-card-content span {
      font-family: Visby CF;
      font-size: 12px;
      font-style: normal;
      font-weight: 100;
      line-height: 15px;
      letter-spacing: .02em;
    }
  }

  @media (min-width: @screen-lg) {
    .de-moi-description {
      width: 68%;
    }

    
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
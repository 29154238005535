@import '../../styles/style-constants.less';
#collection {
  .title-header {
    padding-top: 5%;
    padding-bottom: 4%;
  }

  .titleNew {
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 113%;
    /* or 27px */

    letter-spacing: 0.03em;

    color: #333333;
  }

  .descriptionNew {
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    /* or 21px */

    letter-spacing: 0.03em;
    margin: 30px 0px 90px 90px;
    color: #000000;
  }

  .productCenter {
    display: flex;
    justify-items: center;
  }

  .highlighting-section {
    .second-row {
      margin-top: -240px;
      margin-bottom: 80px;
    }
  }

  .new-product-section {
    margin-top: 100px;
  }

  @media (max-width: @screen-sm-max) {
    margin-top: 60px;
    .titleNew {
      font-weight: 500;
      font-size: 16px;
    }

    .description-wrapper {
      margin-top: 40px;
    }

    .descriptionNew {
      margin-left: 0px;

      font-family: Visby CF;
      font-style: normal;
      font-weight: 300;
      font-size: 11px;
      margin: 20px 0px 20px 0px;
    }

    .new-product-section {
      margin-top: 89px;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import "../../../styles/style-constants.less";

.img-text-box > img {
  width: 100%;
  object-fit: cover;
  max-height: 631px;
}

.img-text-box {
  position: relative;
}
.img-text {
  position: relative;
  top: -1rem;
}

@media (min-width: @screen-md-min) {
  .img-text {
    top: -3rem;
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
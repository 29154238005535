@import '../../../styles/style-constants.less';

#best-sales {
  margin-top: 50px;

  .title {
    font-style: normal;
    text-align: left;
    font-weight: 400;
    margin-left: 5%;
  }
  .sales-title-normal {
    font-family: Frunchy;
    font-size: 32px;
    line-height: 31px;
    letter-spacing: 0em;
    color: #333333;
  }
  .sales-title-italic {
    font-family: Everty Glorial;
    font-size: 37px;
    line-height: 40px;
    letter-spacing: 0.03em;
    color: #4c282e;
  }

  .sales-product-card {
    // display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 95%;
    margin: 0 auto;
    margin-bottom: 50px;
    cursor: pointer;
    transition: 0.4s ease-in-out;
  }
  .sales-product-card:hover {
    transform: scale(1.03);
    opacity: 0.8;
  }
  .card-image-wrapper {
    width: 100%;
  }
  .card-image-wrapper img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    cursor: pointer;
  }

  .card-footer {
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-family: Visby CF;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.03em;
    color: #51262e;
    padding-top: 0.7rem;
  }
  .product-prices div{
    white-space: nowrap;
  }
  .card-prices {
    display: block;
  }
  .sold-price {
    text-decoration: line-through;
    opacity: 0.5;
  }

  @media (min-width: @screen-sm-min) {
    .sales-title-normal {
      font-size: 46px;
      line-height: 46px;
    }
    .sales-title-italic {
      font-size: 44px;
      line-height: 50px;
    }
  }

  @media (min-width: @screen-md-min) {
    margin-top: 70px;

    .sales-product-card {
      display: flex;
    }

    .sales-title-normal {
      font-size: 60px;
      line-height: 65px;
    }
    .sales-title-italic {
      font-size: 60px;
      line-height: 65px;
    }

    .best-sales-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      row-gap: 40px;
    }
    .sales-product-card {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 31%;
      margin: 0 auto;
    }

    .card-image-wrapper img {
      height: 300px;
    }
    .card-footer {
      font-size: 0.9rem;
    }
  }

  @media (min-width: @screen-lg-min) {
    margin-top: 90px;

    .sales-title-normal {
      font-size: 80px;
      line-height: 80px;
    }
    .sales-title-italic {
      font-size: 80px;
      line-height: 100px;
    }

    .card-image-wrapper img {
      height: 400px;
    }

    .card-footer {
      font-size: 1.1rem;
    }
  }

  @media (min-width: @screen-xl-min) {
    margin-top: 120px;

    .sales-title-normal {
      font-size: 100px;
    }
    .sales-title-italic {
      font-size: 100px;
    }

    .sales-product-card {
      width: 30%;
    }
    .card-image-wrapper img {
      height: 450px;
    }
    .card-footer {
      font-size: 1.3rem;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
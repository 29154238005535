.search-wrapper {
   position: relative;
   margin: 0px 10px;

   display: flex;
   justify-content: center;
   align-items: center;

   .search-icon {
      margin-left: 5px;
      &-hidden{
         font-size: 0px;
      }
      &-visible{
         font-size: 16px;
      }
   }


   .search-input-hidden {
      transform:scaleY(0);
      height: 0px;
      border: none;
   }

   .search-input-visible{
      height: 34px;
      // border: 1px solid #d9d9d9;
      transform:scaleY(1);
      transition: all 0.1s ease-out;
      transform-origin:top;

      .ant-input{
         font-size: 16px;
      }
   }

   .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
      border: 1px solid #d9d9d9 !important;  
   }

   .ant-input-affix-wrapper-focused{
      border: 1px solid #d9d9d9 !important;
      box-shadow: none;
   }

}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
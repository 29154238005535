@import "../../styles/style-constants.less";
#team {
  margin-top: 83px;
  margin-bottom: 50px;

  .elles-vous-sublime {
    width: 108%;
    margin-left: -4%;
    margin-right: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    background-color: #4c282e;

    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .creatrice-link {
    font-family: Visby CF !important;
    font-size: 16px;
  }

  .team-card {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 40px;
  }

  .team-card-content-img {
    width: 100%;
    margin-bottom: 20px;
  }

  .team-card-title-section {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .person-name {
    font-family: Everty Glorial;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 39px;
    letter-spacing: 0.03em;
    text-align: left;
    color: black;

    justify-self: flex-start;
    margin-block: 0;
  }

  .person-position {
    font-family: Visby CF;
    font-size: 9px;
    font-style: normal;
    font-weight: 100;
    line-height: 12px;
    letter-spacing: 0.03em;
    text-align: center;
    color: black;

    justify-self: flex-end;
    margin-block: 0;
  }

  .team-card-content-text > p {
    font-family: Visby CF;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px;
    letter-spacing: 0.03em;
    text-align: left;

    color: black;
  }

  .person-position-wrapper {
    width: 114px;
  }

  .title-section {
    display: flex;
    flex-direction: column;
  }

  .normal-title {
    font-family: Frunchy;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.03em;
    text-align: left;

    color: #e6ceb5;
    margin-block: 0;
  }

  .italic-title {
    font-family: Everty Glorial;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #e6ceb5;
    margin-block: 0;
  }

  .elles-vous-sublime-text-content {
    padding-top: 12px;
  }

  .elles-vous-sublime-text-content > p {
    font-family: Visby CF;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px;
    letter-spacing: 0.03em;
    text-align: left;

    color: white;
  }

  .notreTeamSection {
    display: flex;
    flex-flow: row wrap;
    margin-top: 40px;
  }

  .team-card {
    flex: 0 0 100%;
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 40px;
  }

  .team-card-content {
    width: 100%;
  }

  @media (min-width: @screen-sm-min) {
    .team-card {
      padding-right: 0;
      margin: 30px auto;
    }
  }

  @media (min-width: @screen-md-min) {
    margin-top: 55px;
    margin-bottom: 20px;

    .team-card {
      flex: 0 0 50%;
      margin-bottom: 60px;
    }

    .notreTeamSection {
      margin-top: 80px;
    }

    .person-position-wrapper {
      width: 292px;
    }

    .team-card-content-text > p {
      font-family: Visby CF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.03em;
      text-align: left;
      color: black;
    }

    .team-card-title-section {
      height: 70px;
      margin-bottom: 20px;
    }

    .team-card-content {
      width: 80%;
      margin-right: 0;
      margin-left: auto;
    }

    .person-name {
      font-family: Everty Glorial;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 39px;
      letter-spacing: 0.03em;
      text-align: left;
      margin-block: 0;
    }

    .person-position {
      font-family: Visby CF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.03em;
      text-align: center;
      color: black;
      padding: 0 10px;
      margin-block: 0;
    }

    .title-section {
      flex: 0 0 50%;
      display: flex;
      flex-flow: column nowrap;
    }

    .elles-vous-sublime-text-content {
      flex: 0 0 50%;
      padding-top: 18px;
    }

    .elles-vous-sublime-text-content > p {
      font-family: Visby CF;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 21px;
      letter-spacing: 0.03em;
      text-align: left;
      color: white;
    }

    .normal-title {
      font-family: Frunchy;
      font-size: 100px;
      font-style: normal;
      font-weight: 400;
      line-height: 90px;
      letter-spacing: 0.03em;
      text-align: left;

      color: #e6ceb5;
      margin-block: 0;
    }

    .italic-title {
      font-family: Everty Glorial;
      font-size: 100px;
      font-style: normal;
      font-weight: 400;
      line-height: 90px;
      letter-spacing: 0.03em;
      text-align: left;
      color: #e6ceb5;
      margin-block: 0;
    }

    .elles-vous-sublime {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding-top: 69px;
      padding-bottom: 69px;
      padding-left: 98px;
      padding-right: 98px;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../styles/style-constants.less';

#Confiance {
  font-family: Visby CF;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.03em;
  color: #000000;

  .titleSection {
    padding-top: 5%;
    padding-bottom: 3%;
  }
  .introContent {
    margin-bottom: 50px;
  }
  .contentConfiance {
    margin: 5%;
  }
  .italicSize {
    font-size: 41px;
  }
  .textIntro {
    text-align: justify;
    text-justify: inter-word;
  }

  .textEvent {
    width: 90%;
    text-align: justify;
    text-justify: inter-word;
  }
  .textRester {
    width: 75%;
    text-align: justify;
    font-family: Visby CF;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 130%;

    align-items: center;
    letter-spacing: 0.03em;

    color: #000000;
    text-align: justify;
    text-justify: inter-word;
    margin: 20px 0;
  }
  .evenement {
    margin-bottom: 80px;
  }
  .imageRester {
    width: 90%;
  }

  .video-content-area {
    margin-top: 40px;
    margin-bottom: 60px;
  }

  .imgEvents {
    width: 100%;
  }
  .dateEvents {
    text-align: right;
    font-size: 24px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.03em;
    color: #333333;
    opacity: 0.58;
  }
  .doubleImage {
    width: 90%;
    margin-top: 45px;
    display: flex;
    position: relative;
  }

  .logoEvents {
    display: flex;
    float: left;
    width: 30%;
    align-items: flex-end;
  }
  .imagelog {
    position: absolute;
    display: flex;
    right: 0;
    max-width: 40%;
  }

  .defilBtn {
    font-family: Visby CF;
    font-style: normal;
    font-weight: 100;
    font-size: 64px;
    line-height: 80px;
    /* identical to box height, or 126% */

    display: flex;
    align-items: center;
    letter-spacing: 0.03em;

    color: #000000;
  }
}

@media (max-width: 768px) {
  #Confiance {
    margin-top: 50px;

    .textEvent {
      width: 100%;
      text-align: justify;
      text-justify: inter-word;
    }

    .doubleImage {
      width: 100%;
      margin-top: 45px;
      margin-bottom: 30px;
    }
    .logoEvents {
      display: flex;
      float: left;
      width: 30%;
    }
    .imagelog {
      position: absolute;
      display: flex;
      right: 0;
    }

    .imageRester {
      width: 100%;
    }
  }

  @media (max-width: @screen-xs-max) {
    #Confiance {
      .contentConfiance {
        margin: 0%;
      }
      .titleSection {
        padding-top: 10%;
        padding-bottom: 5%;
      }
      .introContent {
        margin-bottom: 40px;
      }

      .evenement {
        margin-bottom: 40px;
      }
      .imageRester {
        width: 100%;
      }
      .video-content-area {
        height: 300px;
      }

      .logoEvents {
        padding-top: 30px;
      }

      .imagelog {
        width: 60%;
        margin-bottom: 0;
      }

      .textEvent {
        width: 100%;
      }

      .textRester {
        width: 100%;
        margin-bottom: 50px;
      }
      .spaceColumEvents {
        margin-top: 50px;
      }
      .defilBtn {
        font-size: 40px;
      }

      .doubleImage {
        margin-top: 35px;
        display: flex;
      }
      .textEvents {
        width: 100%;
      }
    }
  }
}

// @media (min-width: @screen-sm-min) {
// }
// // large screen
// @media (min-width: @screen-lg-min) {
// }

// @media (min-width: @screen-md-min) {
// }

@media (min-width: 750px) and (max-width: 780px) {
  #Confiance {
    .textRester {
      font-size: 12px;
      width: 100%;
    }

    .imageRester {
      width: 90%;
    }
    .header-h1-text-normal {
      font-size: 58px;
      line-height: 45.5px;
    }
    .imagelog {
      margin-left: 60%;
      width: 30%;
    }
    .textIntro {
      font-size: 12px;
    }
    .textIntro {
      font-size: 12px;
    }
    .logoEvents {
      position: absolute;

      width: 30%;
    }
    .doubleImage {
      margin-top: 50px;
      display: flex;
    }
    .spaceColumEvents {
      margin-top: 150px;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../styles/style-constants.less';
#prestations {
  background-image: url("../../assets/images/hairmatch/sublime.svg"),url("../../assets/images/hairmatch/sublime.svg");
  background-repeat: no-repeat;
  background-position: bottom 20% left -15%,top 20% right -15%;
  background-size: 25%;

  .prestation-container {
    margin: 5%;
  }
  .title-header {
    padding-top: 5%;
    padding-bottom: 4%;
  }
  .prestation-title {
    font-family: Visby CF;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #000000;
  }
  .prestation-title-mobile {
    display: none !important;
  }
  .prestation-image {
    justify-content: flex-end;
    display: flex;
  }
  .image-prestation {
    width: 80%;
  }
  .content-description {
    justify-content: flex-end;
    display: flex;
    width: 100%;
    align-self: center;
  }
  .description-col {
    display: flex;
    align-items: center;
  }
  .prestation-description {
    width: 384px;
    font-family: Visby CF;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.03em;
    color: #000000;
  }

  @media (max-width: @screen-lg) {
    .prestation-description {
      width: 100%;
      padding-left: 20px;
    }
  }

  @media (max-width: 768px) {
    .prestation-card__left-wrapper {
      display: grid;
      align-items: flex-end;
    }

    .title-header {
      padding-top: 12%;
      padding-bottom: 4%;
    }

    .prestation-description {
      width: 100%;
      font-family: Visby CF;
      font-style: normal;
      font-weight: 300;
      font-size: 11px;
      line-height: 130%;
      letter-spacing: 0.03em;
      color: #000000;
    }

    .image-prestation {
      width: 100%;
      object-fit: fill;
    }
  }
}
@media (max-width: @screen-xs-max) {
  #prestations {
    // .container {
    //   padding: 0px !important;
    // }
    .title-header {
      padding-top: 30%;
      padding-bottom: 8%;
    }

    .prestation-container {
      margin: 0% 0% 8% 0%;
    }
    .content-description {
      justify-content: center;
    }
    .image-prestation {
      width: 100%;
    }
    .prestation-description {
      margin: 8% 0% 8% 0%;
      width: 300px;
      font-style: normal;
      font-weight: 300;
      font-size: 11px;
    }
    .prestation-title {
      display: none !important;
    }
    .prestation-title-mobile {
      display: block !important;
      font-family: Visby CF;
      font-style: normal;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #000000;
      margin-left: 5%;
      font-weight: 300;
      font-size: 16px;
      line-height: 130%;
    }
  }
}

@media (max-width: 1025px) {
  #prestations .prestation-description {
    font-size: 14px;
  }
}
@media (min-width: @screen-sm-min) {
}
// large screen
@media (min-width: @screen-lg-min) {
}

@media (min-width: @screen-md-min) {
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../../styles/style-constants.less';

.resetDiv {
  background-size: cover;
  height: 250px;
}

.successed_reset_pwd-message,
.error_reset_pwd-message {
  color: #51262e;
  font-family: Visby CF;
  font-size: 14px;
}

.error_reset_pwd-message {
  color: #ff4d4f;
}

.retry-button {
  font-size: 14px;
  text-decoration: underline;
  font-family: Visby CF;
  span {
    text-decoration: underline;
  }
}

.colResetDiv {
  margin: 0 auto;
}

.successResetPassworMsg {
  font-size: 24px;
}

.ant-picker {
  // width: 85%;
  height: 36px;
}

// .content-form label, .content-form p {
//     font-size: 14px;
// }

#details {
  padding-left: 40px;
  .fom__label {
    font-size: 15%;
  }
  .ant-input-number {
    padding: 2px;
    // width: 85%;
  }

  .ant-select-selector {
    height: 46px;
  }

  .country {
    // width: 85%;
    font-size: 20px;
  }

  .details__title {
    font-family: Frunchy;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 58px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
  }

  .details__buttons {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  .details__button {
    border: none;
    text-decoration: underline;
    background-color: transparent;
    font-family: Visby CF;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.03em;
    cursor: pointer;
    span {
      text-decoration: underline !important;
    }
  }

  .switch-password {
    display: block;
    width: 100%;
    font-family: Visby CF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 30px;
  }

  .switch__col {
    display: block;
  }

  .switch__col label,
  input {
    display: block;
    // width: 85%;
  }

  input {
    font-size: 20px;
    padding: 6px 10px;
  }

  label {
    font-size: 25px;
    // margin-bottom: 5px;
  }

  .switch-password__submit {
    margin-top: 10px;
    margin-bottom: 40px;
  }

  .details__billing {
    margin-top: 40px;
  }

  .billing__infos {
    list-style-type: none;
    padding-left: 0;
    margin: 20px 0;
  }

  .billing__items {
    font-family: Visby CF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
  }

  @media (max-width: @screen-lg) {
    .details__title {
      font-size: 38px;
      line-height: 43px;
    }

    .details__button {
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0em;
    }

    .switch-password {
      font-size: 14px;
      line-height: 15px;
      margin-top: 30px;
    }
  }

  @media (max-width: @screen-md) {
    .details__title {
      font-size: 28px;
      line-height: 33px;
    }

    .details__button {
      font-size: 12px;
      line-height: 11px;
      letter-spacing: 0em;
    }

    .billing__items {
      font-size: 12px;
      line-height: 15px;
    }

    .switch__col {
      display: unset;
      grid-template-columns: max-content;
      width: 100%;
    }
  }

  @media (max-width: @screen-sm) {
    padding-left: 0;

    .navbar-toggle__button {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }

    .navbar-toggle__button img {
      width: 20px;
      padding-bottom: 5px;
    }

    .details__title {
      font-size: 36px;
      line-height: 24px;
      margin-bottom: 20px;
      padding-left: 0;
    }

    .details__button {
      font-size: 14px;
      line-height: 16px;
    }

    .details__buttons {
      display: grid;
      grid-template-columns: max-content;
    }

    .details__logout {
      text-align: left;
      margin-top: 10px;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../../../styles/style-constants.less';

.banner-img-home-m {
  position: relative;
  // margin-bottom: 1.2rem;
}

.banner-img-wrapper-m {
  width: 100%;
  height: auto;

  overflow: hidden;
}

.banner-img-std-m {
  width: 100%;
  height: auto;
}

.slider-wrapper {
  width: 108%;
  margin-left: -4%;
  margin-right: auto;
}

.header-text-section-m {
  margin-bottom: -1.2rem;
}

.banner-img-home-desktop-m {
  display: none;
}

.banner-img-home-mobile-m {
  display: block;
}

.navigation-arrow-wrapper-m {
  position: absolute;
  width: 100%;
  top: 120px;
  z-index: 10;
}

.easyLeftNavigation-m {
  position: absolute;
  width: 25%;
  height: 100%;
  top: 0;
  left: 0;
}

.easyLeftNavigation-m:hover,
.easyRightNavigation-m:hover {
  cursor: pointer;
}

.easyRightNavigation-m {
  position: absolute;
  width: 25%;
  height: 100%;
  top: 0;
  right: 0;
}

@media (min-width: @screen-md-min) {
  .banner-img-home-mobile-m {
    display: none;
  }

  .navigation-arrow-wrapper-m {
    top: 300px;
  }

  .banner-img-home-desktop-m {
    display: block;
  }

  .header-text-section-m {
    z-index: 1;
    margin-bottom: -2.8rem;
  }

  .banner-img-home-m {
    position: relative;
    // margin-bottom: 2.8rem;
    z-index: 0;
  }

  .slider-wrapper {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .banner-img-wrapper-m {
    width: 100%;
    overflow-y: hidden;
  }

  .banner-img-std-m {
    width: 100%;
    object-fit: cover;
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../../styles/style-constants.less';

#confirmation {

    margin-top: 50px;

    .confirmation__section {
        margin-top: 40px;
    }

    .confirmation__radio {
        display: flex;
        width: 100%;
        line-height: 21px;
        text-align: left;
        margin-top: 20px;
        align-items: center;
    }

    .confirmation__radio label {
        margin-left: 20px;
    }

    .confirmation__radio a {
        color: #000F08;
        text-decoration: underline;
    }

    @media (max-width: 768px) {

        margin-top: 0;

        .confirmation__title {
            display: none;
        }
    
        .confirmation__subtitle {
            display: none;
        }

        .confirmation__radio {
            font-size: 14px;
            line-height: 17px;
        }
    }

    @media (max-width: @screen-sm) {

        .confirmation__radio {
            font-size: 9px;
            line-height: 12px;
        }

        .confirmation__radio label {
            margin-left: 20px;
            align-items: center;
            font-size: 9px;
        }

    }

}
@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
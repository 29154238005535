@import '../../styles/style-constants.less';
#blog {
  .banner-section {
    margin-bottom: 1.2rem;
  }
  .content-title {
    height: 100px;
  }
  .detail,
  .blog {
    margin-top: 40px;
  }
  .suite-blog {
    margin-top: 90px;
  }
  .title-tutorial {
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 113%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #333333;
  }

  .empty-list {
    height: 150px;
    margin: auto;
    text-align: center;
    font-size: 36px;
  }

  .description {
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    color: #333333;
    margin-top: 25px;
  }
  .BlogImage {
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .title-blog {
    font-family: Frunchy;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 75%;
    letter-spacing: 0.03em;
    color: #333333;
  }
  .btnblog {
    text-align: end;
  }
  .space-pagination {
    margin-top: 60px;
    margin-bottom: 40px;
  }

  .banner-section {
    margin-bottom: 1.2rem;
  }
  .space-content {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .content-blog {
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
  }
  .title-also {
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 113%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #333333;
  }
  .title-post-recent {
    margin-bottom: 30px;
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 113%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #111111;
  }
  .liste {
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    color: #443f3f;
  }

  .post-recent {
    margin-bottom: 30px;
    margin-top: 30px;
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 113%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #111111;
  }

  .inputsearch {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #443f3f;
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    color: #443f3f;
  }
  .ant-pagination-item {
    background-color: transparent;
    border: none;
  }
  .ant-pagination-next {
    display: none;
  }
  .ant-pagination-prev {
    display: none;
  }
  .ant-input {
    background-color: transparent;
  }
  .ant-btn {
    background-color: transparent;
  }
  .ant-btn:hover {
    background-color: transparent;
  }
  .pagination {
    text-align: end;
  }
}
@media (max-width: @screen-xs-max) {
  #blog {
    .space-content {
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .detail {
      margin-top: 60px;
    }

    .title-also {
      font-size: 16px;
    }
    .space-pagination {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .title-post-recent {
      font-size: 16px;
      margin-top: 30px;
    }

    .detail,
    .blog {
      margin-top: 60px;
    }
    .pagination {
      display: none;
    }
    .suite-blog {
      margin-top: 40px;
    }
    .BlogImage {
      margin-top: 0px;
    }
  }
}

// @media (min-width: @screen-sm-min) {
// }
// // large screen
// @media (min-width: @screen-lg-min) {
// }

// @media (min-width: @screen-md-min) {
// }

@media (min-width: 750px) and (max-width: 780px) {
  #blog {
    .article-blog-header {
      position: relative;
      font-family: Frunchy;
      font-size: 34px;
      font-style: normal;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
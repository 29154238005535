@import '../../styles/style-constants.less';

#home {
  h2,
  p,
  button,
  span {
    color: #333333;
  }

  position: relative;
  margin-bottom: 100px;
  margin-top: 83px;

  .div-center-position {
    width: fit-content;
    margin: auto;
  }

  .ads-sample-text {
    font-family: Visby CF;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.03em;
    text-align: left;
  }

  .banner-section {
    position: relative;
    margin-bottom: 1.2rem;
  }

  .youtube-section {
    margin-top: 70px;
  }

  .youtube-text {
    font-family: Visby CF;
    font-size: 12px;
    line-height: 14px;
  }

  @media (min-width: @screen-md-min) {
    margin-top: 55px;

    .banner-section {
      margin-bottom: 2.8rem;
    }

    .max-width-800-div {
      max-width: 809px;
    }

    .ads-sample-text {
      font-family: Visby CF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.03em;
      text-align: left;
    }

    .produit-advantage {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;
      margin-top: 1rem;
    }

    .avant-apres-img {
      display: grid;
      grid-template-columns: 47.5% 47.5%;
      grid-template-rows: 100%;
      column-gap: 5%;
      height: max-content;

      margin-top: 100px;
    }

    .youtube-text {
      font-family: Visby CF;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import './color.less';
@import './style-constants.less';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

h1,
h2,
h3 {
  margin: 0px;
  padding: 0px;
  font-weight: normal;
  color: inherit;
}

.ql-size-large {
  font-size: 25px;
  line-height: 42px;
  color: #3b052b !important;
}

.ql-size-huge {
  font-size: 40px;
  line-height: 50px;
  color: #3b052b !important;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  font-size: 22px;
}

.ant-select-item {
  font-size: 20px !important;
}

@media (max-width: @screen-xs-max) {
  .large-title {
    font: normal normal bold 30px/40px Georgia Bold;
    display: block;
  }

  .font-18 {
    font: normal normal normal 16px/20px Georgia Regular;
  }

  .ql-size-large {
    font-size: 16px;
    line-height: 20px;
    color: #3b052b;
  }

  .ql-size-huge {
    font-size: 30px;
    line-height: 40px;
    color: #3b052b;
  }
}

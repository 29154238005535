@import '../../styles/style-constants.less';
#history {
  h2,
  p,
  button,
  span {
    color: #333333;
  }

  .section-content-img {
    width: 108%;
    margin-left: -4%;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }

  .section-content-main {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
  }

  .align-text-at-end {
    align-self: end;
  }

  .align-text-at-center {
    align-self: center;
    justify-self: center;
  }

  .section-text-content-wrapper {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .accompagnement-text-content-wrapper {
    width: 100%;
    margin-bottom: 20px;
  }

  .accompagnement-with-text {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .subsection-text {
    font-family: Visby CF;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;

    color: black;
  }

  .section-text-content-wrapper > button {
    margin-top: 1rem;
  }

  .video-content-area {
    // margin-top: 17px;
    height: 195px;
    width: 108%;
    margin-left: -4%;
    margin-right: auto;
  }

  .img-with-text {
    margin-top: -1.3rem;
  }

  .section-list-items {
    margin-left: 4%;
    margin-right: 4%;
    margin-bottom: 20px;
    padding-inline-start: 0;
    background-color: transparent;
    list-style: none;
  }

  .section-list-items > li {
    font-family: Visby CF;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.03em;
    text-align: left;
    margin: 5px 0;
    color: #333333;
    list-style: none;
  }

  .subsection-header {
    font-family: Frunchy;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;

    color: #333333;
  }

  .accompagnement-content-img {
    width: 100%;
  }

  .depuis-treize-ans-content-img {
    width: 100%;
  }

  .advantage-services-garantie {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 100%;
  }

  .section-item-card {
    position: relative;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
  }

  .yellow-bg-color {
    background-color: #faf5ed;
  }

  .gray-1-bg-color {
    background-color: #ece6e7;
  }

  .gray-2-bg-color {
    background-color: #dadada;
  }

  .header-section {
    width: 100%;
    position: relative;
  }

  .header-wheel-img {
    position: absolute;
    right: 0px;
    top: 5px;
  }

  .header-section-text {
    width: 90%;
    font-family: Visby CF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-align: left;
  }

  .content-section {
    width: 100%;
    margin-top: 10px;
  }

  .content-section > p {
    font-family: Visby CF;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.03em;
    text-align: left;
    color: black;

    margin-block: 0;
  }
  margin-top: 83px;

  .banner-section {
    margin-bottom: 1.2rem;
  }

  @media (min-width: @screen-md-min) {
    margin-top: 55px;

    .banner-section {
      margin-bottom: 2.8rem;
    }

    .accompagnement-with-text {
      margin-top: 80px;
      margin-bottom: 80px;
    }

    .accompagnement-text-content-wrapper {
      max-width: 80%;
      padding-left: 20px;
    }

    .video-content-area {
      margin-top: 70px;
      height: 618px;
      width: 100%;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
    }

    .artisan-sublime-chiffre {
      margin-top: 50px;
    }

    .img-with-text {
      margin-top: -3rem;
    }

    .section-list-items {
      margin-left: unset;
      margin-right: unset;
      margin-top: 60px;
      margin-bottom: 40px;
      list-style: none;
      padding-inline-start: 0;
    }

    .section-list-items > li {
      font-family: Visby CF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.03em;
      text-align: left;
      color: #333333;
      list-style: none;
    }

    .section-content-wrapper {
      display: grid;
      grid-template-columns: 40% 58%;
      grid-template-rows: 100%;
      column-gap: 12px;
    }

    .depuis-13-ans-content-wrapper {
      display: grid;
      grid-template-columns: 58% 40%;
      grid-template-rows: 100%;
      column-gap: 12px;
    }

    .section-content-img {
      width: 100%;
      margin-left: unset;
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 2;
      grid-column-end: 3;
    }

    .accompagnement-content-img {
      height: 378px;
      width: auto;
      margin-left: auto;
      margin-right: auto;
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 1;
      grid-column-end: 2;
    }

    .depuis-treize-ans-content-img {
      height: 318px;
      width: 100%;
      margin-left: auto;
      margin-right: 0;
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 2;
      grid-column-end: 3;
    }

    .section-content-main {
      grid-row-start: 1;
      grid-row-end: 2;

      grid-column-start: 1;
      grid-column-end: 2;
    }

    .accompagnement-content-main {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .section-text-content-wrapper {
      width: 80%;
      margin: auto;
    }

    .subsection-header {
      font-family: Frunchy;
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: 43px;
      letter-spacing: 0em;
      text-align: left;
      color: #333333;
    }

    .subsection-text {
      font-family: Visby CF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      color: black;
    }

    .depuis-treize-ans-section {
      margin-top: 70px;
    }

    .header-section > h3 {
      font-family: Visby CF;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 31px;
      letter-spacing: 0.03em;
      text-align: left;
      color: black;
    }

    .content-section > p {
      font-family: Visby CF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.03em;
      text-align: left;
      color: black;

      margin-block: 0;
    }

    .advantage-services-garantie {
      display: flex;
      margin-top: 80px;
      margin-bottom: 100px;
    }

    .header-section-text {
      width: 194px;
      font-family: Visby CF;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 31px;
      letter-spacing: 0.03em;
      text-align: left;
    }

    .section-item-card {
      position: relative;
      width: 329px;
      height: 395px;
      padding: 30px;
    }

    .header-section {
      width: 100%;
      position: relative;
    }

    .header-wheel-img {
      position: absolute;
      right: unset;
      left: 197px;
      top: 5px;
    }

    .content-section {
      width: 260px;
      margin-top: 19px;
    }
  }

  @media (min-width: @screen-lg-min) {
    .accompagnement-content-img {
      height: 518px;
    }

    .depuis-treize-ans-content-img {
      height: 418px;
    }
  }

  @media (min-width: @screen-xl-min) {
    .depuis-treize-ans-content-img {
      height: 518px;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../styles/style-constants.less';
@import '../../styles/color.less';

.app-footer {
  font-family: Visby CF;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 163.5%;
  margin-top: 50px;

  padding: 0px !important;
  background-color: #fff;

  .container {
    margin-top: 64px;
    margin-bottom: 27px;
  }

  @media (min-width: 1600px) {
    .container {
      margin-right: auto;
      margin-left: auto;
      max-width: 1300px;
    }
  }

  letter-spacing: 0.03em;

  .footer-item {
    margin-top: 4px;
  }

  .app-logo {
    height: 150px;
    width: auto;
  }

  .item-menu {
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 239.5%;
    letter-spacing: 0.03em;
    // text-decoration-line: underline;
    color: #333333;
    letter-spacing: 0.03em;
    margin-block: 0;
  }

  .item-header {
    display: block;
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 113%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }

  .item-header.second {
    margin-top: 20px;
  }

  .condition {
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 239.5%;
    /* or 29px */

    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    // text-decoration-line: underline;

    /* Gray 1 */

    color: #333333;
  }

  .address-description {
    font-family: Visby CF;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 163.5%;
    /* or 26px */

    letter-spacing: 0.03em;

    font-style: normal;
  }

  .socialrow {
    margin-top: 30px;
  }

  .item-header {
    margin-bottom: 12px;
  }

  .condition {
    margin-top: 28px;
  }

  @media (max-width: @screen-xs-max) {
    .address-description {
      font-size: 12px;
      line-height: 163.5%;
      color: #333333;
    }

    .item-header {
      font-size: 14px;
      line-height: 113%;
      color: #333333;
    }

    .item-menu {
      font-size: 10px;
      line-height: 239.5%;
    }

    .app-logo {
      height: 100px;
    }

    .logo-wrapper {
      display: flex;
      justify-content: center;
    }

    .condition {
      margin-top: 0px;
    }
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
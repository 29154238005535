@import "../../../styles/style-constants.less";

.produit-navigation-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}

.produit-navigation-wrapper-on-image {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.make-it-clickable:hover {
  cursor: pointer;
}

@media (min-width: @screen-md-min) {
  .produit-navigation-wrapper {
    margin-top: 50px;
  }

  .produit-navigation-wrapper-on-image {
    justify-content: space-between;
    padding-left: 80px;
    padding-right: 80px;
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
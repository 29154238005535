@import '../../../styles/style-constants.less';
.delai-duree {
  margin-top: 50px;
}
.titlefaq-bottom {
  margin-bottom: 30px;
}
.img-faq-position {
  text-align: center;
}

.header-h1-text-italic {
  color: #4c282e;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  width: 21px;
  height: 12px;
}

.ant-collapse {
  background-color: transparent;
  border: none;
}
.ant-collapse-content {
  background-color: transparent;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 20px 0px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 20px 0px 20px 0px;
  padding-right: 80px;
}

.font-collapse-1 .ant-collapse-header {
  font-family: Visby CF;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 130%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #000000;
}
.font-collapse-2 .ant-collapse-header {
  font-family: Visby CF;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #000000;
}
.font-collapse-1 .ant-collapse-content-box p {
  font-family: Visby CF;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.03em;
  color: #000000;
}

.font-collapse-2 .ant-collapse-content-box p {
  font-family: Visby CF;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.03em;
  color: #000000;
}
.collapse-title-h1 {
  font-family: Visby CF;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  margin-bottom: 30px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #000000;
}
.space-collapse {
  margin-bottom: 120px;
  margin-left: 100px;
  font-size: 16px !important;


  .ant-collapse-header{
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-size: 18px !important;
  }
}

.margRight-title {
  margin-right: 30px;
}
.title-faq2 {
  margin: 75px 0px 80px 0px;
}
@media (max-width: @screen-xs-max) {
  .img-faq-position {
    text-align: left;
  }
  .titleMobileCollapse {
    margin-top: 30px;
  }
  .titlefaq-bottom {
    margin-bottom: 10px;
  }
  .duree-img {
    width: 100%;
  }
  .font-collapse-1 .ant-collapse-header {
    font-size: 13px;
  }
  .font-collapse-1 .ant-collapse-content-box p {
    font-size: 11px;
  }
  .font-collapse-2 .ant-collapse-header {
    font-size: 11px;
  }
  .font-collapse-2 .ant-collapse-content-box p {
    font-size: 11px;
  }

  .collapse-title-h1 {
    font-size: 13px;
  }
  .space-collapse {
    margin-bottom: 30px;
    margin-left: 0px;
  }
  .collapse-title-h1 {
    font-size: 13px;
    margin-bottom: 0px;
  }
  .delai-duree {
    margin-top: 30px;
  }
  .title-faq2 {
    margin: 30px 0px 30px 0px;
  }
  .margRight-title {
    margin-right: 12px;
  }
}

// @media (max-width: 768px) {
//   .duree-img {
//     width: 80%;
//   }
//   .space-collapse {
//     margin-bottom: 80px;
//     margin-left: 0px;
//   }

// }
//tablette and large phone
@media (min-width: @screen-sm-min) {
  .duree-img {
    width: 80%;
  }
}

// large screen
@media (min-width: @screen-lg-min) {
}

@media (min-width: @screen-md-min) {
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import '../../styles/style-constants.less';
@import '../../styles/color.less';

.qty-container {
    .info-item {
        &--qty-handle {
            position: relative;
            line-height: 20px;
            border: 1px solid;
            border-radius: 20px;
            width: max-content;
            margin-top: 15px;
            button {
              background-color: transparent;
              cursor: pointer;
            }
            button:disabled,
            button[disabled] {
              cursor: not-allowed;
            }
    
            .spiner-wrapper {
              position: absolute;
              width: 100%;
              height: 100%;
              padding-top: 3px;
              background-color: white;
              border-radius: 20px;
              text-align: center;
              z-index: 0;
            }
        }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
      padding: 0 5px;
      font-weight: bold;
    }
    input:focus {
      outline: none;
    }

    .qty {
      &--element {
        padding: 0 12px;
        line-height: 20px;
        border: none;
        height: 27px;
      }
      &--number {
        border-right: 1px solid;
        border-left: 1px solid;
        width: 40px;
        text-align: center;
      }
    }

    @media(max-width: @screen-sm) {
      .info-item {
        &--qty-handle {
          margin-top: 10px;

          .spiner-wrapper {
            padding-top: 0;
          }
        }
      }

      .qty {
        &--element {
          padding: 0 7px;
          height: 20px;
        }
      }
    }
}
@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;
@import "../../../../styles/style-constants.less";

.prise-en-charge-section {
  margin-top: 35px;
}

.prise-en-charge-section-wrapper {
  position: relative;
  top: -1.5rem;
}

.sublime-signature {
  position: absolute;
  top: -12%;
  right: -12%;
  z-index: 10;
}

@media (min-width: @screen-md-min) {
  .prise-en-charge-section {
    margin-top: 10px;
  }

  .prise-en-charge-section-wrapper {
    position: relative;
    top: -1rem;
  }

  .sublime-signature {
    position: absolute;
    top: -30%;
    right: -10%;
    z-index: 10;
  }
}

@primary-color: #51262E;@font-family: Frunchy,'Everty Glorial', Manrope, sans-serif;